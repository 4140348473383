<template>
    <van-skeleton :row="20" :loading="loading">
        <div class="fr-ac-jsb fw" style="padding: 0 15px;">
            <div class="hot-list-box" v-for="(item, index) in qlDataList" :key="index"
                @click="toDetail(item.token_contract, item.view_count)">
                <div class="white-bg"></div>
                <div class="content-box">
                    <van-row gutter="16">
                        <van-col span="8">
                            <img class="label-icon" src="@/assets/img/head.png" alt="">
                        </van-col>
                        <van-col span="16">
                            <div class="hot-title yc-more">{{ item.token_name }}</div>
                        </van-col>
                    </van-row>
                    <van-row class="hot-center-box mt-10">
                        <van-col span="10">
                            <div class="fs-10 mb-30">${{ item.price.toFixed(9) }}</div>
                            <!-- <div class="fr-ac">
                            <div class="green-circle"></div>
                            <div class="fw-b">$0.296</div>
                        </div> -->
                        </van-col>
                        <van-col span="14" class="fc-ae">
                            <div class="fs-10 gray">{{ $util.formattedDate(item.alive_time) }}</div>
                            <div class="green-bg-box fr-ac-jc"
                                :style="item.price_diff < 0 ? 'background: #FF0000;' : ''">
                                <img :class="item.price_diff < 0 ? 'rotate' : ''" class="top-icon"
                                    src="@/assets/img/icon_top.png" alt="">
                                <p>{{ item.price_diff.toFixed(0) }}%</p>
                            </div>
                        </van-col>
                    </van-row>
                    <!-- <van-row class="hot-echart-box">
                    <div class="hot-chart" id="chart"></div>
                </van-row> -->
                    <van-row>
                        <div class="fr-ac-jsb" style="margin-top: 10px;">
                            <div class="fw-b">{{ $t('marketCap') }}</div>
                            <div class="light-gray">${{ formattedMarketCap(item.market_cap) }}</div>
                        </div>
                        <div class="fr-ac-jsb" style="margin-top: 8px;">
                            <div class="fw-b">{{ $t('mobility') }}</div>
                            <div class="light-gray">${{ formattedMarketCap(item.liquidity) }}</div>
                        </div>
                        <div class="fr-ac-jsb" style="margin-top: 8px;">
                            <div class="fw-b">{{ $t('txns') }}</div>
                            <div class="light-gray">{{ item.txcount_24h }}</div>
                        </div>
                        <div class="fr-ac-jsb" style="margin-top: 8px;">
                            <div class="fw-b">{{ $t('holders') }}</div>
                            <div class="light-gray">{{ item.holders }}</div>
                        </div>
                    </van-row>
                </div>
            </div>
        </div>
    </van-skeleton>
</template>

<script>
import * as echarts from 'echarts';
import { getData } from '@/api/api'
export default {
    data() {
        return {
            qlDataList: [], // 潜力新币数据
            loading: true,
            interval: null
        }
    },
    computed: {
        //市值
        formattedMarketCap() {
            return (value) => {
                return this.$util.formatMarketCap(Number(value) || 0);
            }
        },
    },
    watch: {

    },
    mounted() {
        this.getQlData()//潜力新币
        this.createMyInterval()
        // this.initChart('chart', 145, 32);
    },
    beforeDestroy() {
        // 组件销毁前执行的逻辑
        this.clearMyInterval()
    },
    methods: {
        createMyInterval() {
            this.interval = setInterval(() => {
                this.getQlData()
            }, 5000);
        },
        clearMyInterval() {
            clearInterval(this.interval);
        },
        //潜力新币
        getQlData() {
            let chain = ''
            if (this.$store.state.login.chain == 'ETH') {
                chain = 'ethql'
            } else {
                chain = 'solql'
            }
            getData(chain).then((res) => {
                this.qlDataList = res.data.data
                this.loading = false
            });
        },
        // 跳转详情页
        toDetail(address, view_count) {
            this.$router.push({
                path: '/mobile/detail',
                query: {
                    address,
                    view_count
                }
            })
        },
        // initChart(id, width, height) {
        //     // 初始化图表实例
        //     this.chartInstance = echarts.init(document.getElementById(id), null, {
        //         width,
        //         height
        //     });
        //     // 配置图表的选项
        //     const option = {
        //         title: {
        //             text: ''
        //         },
        //         tooltip: {},
        //         legend: {
        //             data: ['销量']
        //         },
        //         xAxis: {
        //             data: []
        //         },
        //         yAxis: {},
        //         series: [{
        //             // name: '销量',
        //             type: 'line',
        //             smooth: true,
        //             data: [5, 100, 10, 80, 50, 20, 10]
        //         }],
        //         dataZoom: [
        //             {
        //                 type: 'slider',  // 可以使用滑动条进行缩放
        //                 show: true,
        //                 start: 0,  // 初始显示的起始比例
        //                 end: 100   // 初始显示的结束比例
        //             }
        //         ]
        //     };

        //     // 使用设置的配置项初始化图表
        //     this.chartInstance.setOption(option);
        // },
    }
}
</script>

<style lang="less" scoped>
.hot-list-box {
    width: 165px;
    height: 230px;
    background: #F7F7F7;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.03);
    border-radius: 7px;
    position: relative;
    margin-bottom: 16px;

    .white-bg {
        width: 165px;
        height: 187px;
        background: #FFFFFF;
        border-radius: 0px 7px 7px 7px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .content-box {
        width: 165px;
        height: 230px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        font-size: 12px;
        padding: 17px 10px;

        .hot-title {
            font-weight: bold;
            font-size: 16px;
            margin-top: 4px;
        }

        .hot-center-box {
            margin-top: 8px;
        }

        .hot-echart-box {
            margin-top: 9px;
            padding-bottom: 16px;
            border-bottom: 1px dashed #E3E3E3;

            .hot-chart {
                width: 145px;
                height: 32px;
            }
        }

        .green-circle {
            width: 5px;
            height: 5px;
            background: #70ED24;
            border-radius: 50%;
            margin-right: 5px;
        }

        .hot-chart {
            width: 74px;
            height: 40px;
        }
    }
}
</style>