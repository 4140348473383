import req from "@/utils/request";
import des from "@/utils/des";
import store from "@/store";

let request = req._axios;
// 登录
export const loginAPI = (address) => {
  return request({
    url: "/api/user/reg2login",
    method: "post",
    headers: {},
    data: {
      username: address, //用户名
      recommend_code: store.state.login.code, //邀请码
    },
  });
};

// 登录2
export const loginAccountAPI = (account, password) => {
  return request({
    url: "/api/user/loginAccount",
    method: "post",
    headers: {},
    data: {
      account, //用户名
      password,
    },
  });
};

// 注册
export const registerAPI = (username, password) => {
  return request({
    url: "/api/user/register",
    method: "post",
    headers: {},
    data: {
      username,
      password,
      recommend_code: store.state.login.code, //邀请码
    },
  });
};

// 轮播图
export const getBannerList = () => {
  return request({
    url: "/api/banner/list",
    method: "get",
    headers: {},
    data: {},
  });
};
