<template>
  <div>
    <div class="bg" v-if="checkIsMobile">
      <!-- <transition :name="transitionName">
      <router-view></router-view>
    </transition> -->
      <router-view></router-view>
      <van-tabbar v-model="active" :placeholder="true" active-color="#70ED24" @change="changeTab">
        <van-tabbar-item icon="home-o" to="/mobile">{{ $t('home') }}</van-tabbar-item>
        <van-tabbar-item icon="bullhorn-o" to="/mobile/vipIndex">{{ $t('VIPSelection') }}</van-tabbar-item>
        <van-tabbar-item icon="friends-o" to="/mobile/member">{{ $t('memberCenter') }}</van-tabbar-item>
      </van-tabbar>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import util from '@/utils/util.js'
export default {
  watch: {
    //使用watch 监听$router的变化
    $route(to, from) {
      //如果to索引大于from索引,判断为前进状态,反之则为后退状态
      // if (to.meta.index > from.meta.index) {
      //   //设置动画名称
      //   this.transitionName = 'slide-left'
      // } else {
      //   this.transitionName = 'slide-right'
      // }
    }
  },
  data() {
    return {
      statusBarHeight: '', //状态栏高度
      transitionName: 'slide-left',
      active: 0,
    }
  },
  computed: {
    ...mapGetters([
      'checkIsMobile', // 映射 `totalPrice` getter
    ])
  },
  created() {
    //邀请码
    if (this.$route.query.code) {
      this.$store.commit('login/changeCode', this.$route.query.code)
    }
    this.statusBarHeight = util.getStatusBarHeight()
    this.active = Number(localStorage.getItem("tabbarIndex")) || 0
  },
  methods: {
    changeTab(index) {
      localStorage.setItem("tabbarIndex", index)
    }
  }
}
</script>

<style lang="less">
@import "assets/css/index.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

div {
  font-size: 12px;
}


// .slide-right-enter-active,
// .slide-right-leave-active,
// .slide-left-enter-active,
// .slide-left-leave-active {
//   will-change: transform;
//   transition: all 500ms;
//   position: absolute;
// }

// // 右滑初始装状态
// .slide-right-enter {
//   // opacity: 0;
//   transform: translate3d(-100%, 0, 0);
// }

// // 右滑执行状态
// .slide-right-leave-active {
//   // opacity: 0;
//   transform: translate3d(100%, 0, 0);
// }

// // 右滑最终状态
// .slide-right-enter-to {
//   // opacity: 1;
//   transform: translate3d(0, 0, 0);
// }

// // 左滑初始装状态
// .slide-left-enter {
//   // opacity: 0;
//   transform: translate3d(100%, 0, 0);
// }

// // 左滑执行状态
// .slide-left-leave-active {
//   // opacity: 0;
//   transform: translate3d(-100%, 0, 0);
// }

// // 左滑最终状态
// .slide-right-enter-to {
//   // opacity: 1;
//   transform: translate3d(0, 0, 0);
// }

.bg {
  width: 100%;

  .status-bar {
    width: 100%;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
}

// .sticky-title {
//   position: sticky;
//   left: 10px;
//   background-color: #FFFFFF;
//   padding-bottom: 10px;
// }

.label-icon {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}

.green-bg-box {
  background: #70ED24;
  border-radius: 10px;
  padding: 4px 7px 4px 5px;
  margin-top: 6px;

  img {
    width: 12px;
    height: 12px;
  }

  p {
    font-size: 10px;
    position: relative;
    top: 1px;
  }
}

.scroll-container::-webkit-scrollbar {
  width: 8px;
  /* 设置垂直滚动条的宽度 */
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #333333;
  /* 滚动条滑块颜色 */
  border-radius: 7px;
  /* 滚动条滑块的圆角 */
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* 滚动条滑块悬停时的颜色 */
}

.scroll-container::-webkit-scrollbar-track {
  background: #CCCCCC;
  /* 滚动条轨道的颜色 */
  border-radius: 4px;
}

.scroll-container::-webkit-scrollbar-track:hover {
  background: #f0f0f0;
  /* 滚动条轨道悬停时的颜色 */
}

@media (min-width: 524px) {
  html {
    font-size: 234.4%;
  }

  div {
    font-size: 14px;
  }

  .common {
    height: 100%;
    width: 1280px;
  }

  .label-icon {
    width: 60px;
    height: 60px;
  }

  .green-bg-box {
    background: #70ED24;
    border-radius: 18px;
    padding: 4px 7px 4px 5px;
    margin-top: 6px;
    min-width: 100px;

    img {
      width: 18px;
      height: 18px;
    }

    p {
      font-size: 14px;
      position: relative;
      top: 1px;
    }
  }
}
</style>
