<template>
    <div class="content-box fc-ac">
        <div class="my-collect-title">{{ $t('emailPush') }}</div>
        <div class="scroll-wrap fc-ac">
            <el-card shadow="hover">
                <div slot="header">
                    <span>{{ $t('bindEmail') }}</span>
                </div>
                <div class="gray mb-20" v-if="myEmail">{{ $t('alreadyBound') }}：{{ myEmail }}</div>
                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="60px">
                    <el-form-item :label="$t('email')" prop="email">
                        <el-input v-model="ruleForm.email"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <div class="pl-40">
                            <el-button type="success" @click="bind()">{{ $t('bind') }}</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-form class="mt-30" :model="ruleForm2" status-icon label-width="130px">
                <el-form-item :label="$t('openPushNotifications')" prop="switch">
                    <el-switch v-model="ruleForm2.switch" active-color="#70ED24" @change="changeSwitch"
                        :disabled="userInfo.email ? false : true">
                    </el-switch>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>

import { profile, getUserInfo } from '@/api/member'
export default {
    components: {

    },
    data() {
        return {
            ruleForm: {
                email: ''
            },
            rules: {
                email: [
                    { required: true, message: this.$t('inputEmail'), trigger: 'blur' },
                    { type: 'email', message: this.$t('inputValidEmail'), trigger: ['blur', 'change'] },
                ],
            },
            ruleForm2: {
                switch: false
            },
            userInfo: {},
            myEmail: ''
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        this.getUserInfo()
    },
    methods: {
        // 获取用户信息
        getUserInfo() {
            getUserInfo().then((res) => {
                if (res.data.code !== 0) {
                    this.$store.commit("login/clearToken");
                    return
                }
                this.userInfo = res.data.data?.userinfo
                this.ruleForm.email = this.userInfo.email //邮箱
                this.myEmail = res.data.data.userinfo.email
                this.ruleForm2.switch = this.userInfo.push_email == 1 ? true : false //邮箱推送状态
            });
        },
        bind() {
            let data = {
                email: this.ruleForm.email,
            }
            profile(data).then(res => {
                if (res.data.code == 0) {
                    this.$message.success(this.$t('bindSuccess'))
                    this.getUserInfo()
                } else {
                    this.$message.success(this.$t('bindFail'))
                }
            })
        },
        changeSwitch(e) {
            let data = {
                push_email: this.ruleForm2.switch ? 1 : 0
            }
            profile(data).then(res => {
                if (e) {
                    this.$message.success(this.$t('switchOn'))
                } else {
                    this.$message.success(this.$t('switchOff'))
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.content-box {
    width: 860px;
    height: 907px;
    background: #2E2E2E;
    border-radius: 10px;
    position: relative;

    .my-collect-title {
        font-weight: 800;
        font-size: 18px;
        color: #70ED24;
        position: absolute;
        top: 21px;
        left: 36px;
    }

    .scroll-wrap {
        width: 840px;
        height: 832px;
        background: #FFFFFF;
        padding: 18px 0;
        margin-top: 65px;
        border-radius: 8px;
        padding-top: 80px;
    }
}
</style>