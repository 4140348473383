<template>
    <div>
        <van-skeleton :row="20" :loading="loading">
            <div class="table-head fr-ac">
                <van-col span="8">
                    <div class="table-head-title">{{ $t('name') }}</div>
                </van-col>
                <van-col span="8">
                    <div class="table-head-title">{{ $t('price') }}</div>
                </van-col>
                <van-col span="8">
                    <div class="table-head-title1">{{ $t('marketCap') }}/{{ $t('mobility') }}</div>
                </van-col>
                <!-- <van-col span="3">
                <div class="green-circle"></div>
            </van-col> -->
            </div>
            <div class="table-body">
                <div class="table-row fr-ac" :class="index % 2 == 1 ? 'bgc' : ''" v-for="(item, index) in dataList"
                    :key="index" @click="toDetail(item.token_contract || item.address, item.view_count)">
                    <van-col span="8">
                        <div class="fr-ac">
                            <img class="icon-img"
                                :src="item.image_icon || item.logo || require('@/assets/img/head.png')" alt="">
                            <div class="ta-c word-break-all">{{ item.token_name || item.symbol }}</div>
                        </div>
                    </van-col>
                    <van-col span="8">
                        <div class="fc-ac">
                            <div style="margin-bottom: 4px;">${{ item.price.toFixed(9) }}</div>
                            <div class="fs-10 fr-ae" v-if="item.price_diff"
                                :class="item.price_diff < 0 ? 'red' : 'green'">
                                <img class="arrow-img" v-if="item.price_diff < 0" src="@/assets/img/icon_bottom_red.png"
                                    alt="">
                                <img class="arrow-img" v-else src="@/assets/img/icon_top_green.png" alt="">
                                <div class="fs-10 pos-r" style="line-height: 1;top:1px;">{{ item.price_diff.toFixed(2)
                                    }}%
                                </div>
                            </div>
                            <div class="fs-10 fr-ae" v-if="item.price_change_percent"
                                :class="item.price_change_percent < 0 ? 'red' : 'green'">
                                <img class="arrow-img" v-if="item.price_change_percent < 0"
                                    src="@/assets/img/icon_bottom_red.png" alt="">
                                <img class="arrow-img" v-else src="@/assets/img/icon_top_green.png" alt="">
                                <div class="fs-10 pos-r" style="line-height: 1;top:1px;">{{
            item.price_change_percent.toFixed(2) }}%
                                </div>
                            </div>
                        </div>
                    </van-col>
                    <van-col span="8">
                        <div class="fc-ac">
                            <div style="margin-bottom: 4px;">${{ formattedMarketCap(item.market_cap) }}</div>
                            <div class="gray fs-10">${{ formattedMarketCap(item.liquidity) }}</div>
                        </div>
                    </van-col>
                    <!-- <van-col span="3">
                    <img class="status-img" src="@/assets/img/status1.png" alt="">
                </van-col> -->
                </div>
            </div>
        </van-skeleton>
    </div>
</template>

<script>
import { getData } from '@/api/api'
import { mapState } from 'vuex'
export default {
    props: {
        type: {
            type: [Number, String],
            default: 1,
        }
    },
    data() {
        return {
            dataList: [],
            loading: true,
            interval: null
        }
    },
    watch: {
        chain(newVal, oldVal) {
            this.getDataList()
        }
    },
    computed: {
        ...mapState({
            chain: state => state.login.chain
        }),
        //市值
        formattedMarketCap(market_cap) {
            return (market_cap) => {
                return this.$util.formatMarketCap(Number(market_cap) || 0);
            }
        },
    },
    mounted() {
        this.getDataList()
        this.createMyInterval()
    },
    beforeDestroy() {
        // 组件销毁前执行的逻辑
        this.clearMyInterval()
    },
    methods: {
        createMyInterval() {
            this.interval = setInterval(() => {
                this.getDataList()
            }, 5000);
        },
        clearMyInterval() {
            clearInterval(this.interval);
        },
        getDataList() {
            let sort = ''
            if (this.chain == 'ETH') {
                switch (this.type) {
                    case '1':
                        sort = 'ethxb'
                        break;
                    case '2':
                        sort = 'ethql'
                        break;
                    case '3':
                        sort = 'ethrm'
                        break;
                    case '4':
                        sort = 'ethrd'
                        break;
                    default:
                        break;
                }
            } else {
                switch (this.type) {
                    case '1':
                        sort = 'solxb'
                        break;
                    case '2':
                        sort = 'solql'
                        break;
                    case '3':
                        sort = 'solrm'
                        break;
                    case '4':
                        sort = 'solrd'
                        break;
                    default:
                        break;
                }
            }

            getData(sort).then((res) => {
                if (this.type == 3) {
                    //热门新币
                    this.dataList = res.data.data.rank
                } else {
                    this.dataList = res.data.data
                }
                this.loading = false
            });
        },
        // 跳转详情页
        toDetail(address, view_count) {
            this.$router.push({
                path: '/mobile/detail',
                query: {
                    address,
                    view_count
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.table-head {
    width: 345px;
    height: 55px;
    background: #F7F7F7;
    border-radius: 5px;
    margin: 21px auto 0;

    .table-head-title,
    .table-head-title1 {
        font-weight: bold;
        font-size: 16px;
        position: relative;
        text-align: center;
        z-index: 0;
    }

    .table-head-title::after,
    .table-head-title1::after {
        width: 36px;
        height: 5px;
        content: '';
        background: #70ED24;
        border-radius: 5px;
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .table-head-title1::after {
        width: 70px;
    }

    .green-circle {
        width: 9px;
        height: 9px;
        background: #70ED24;
        border-radius: 50%;
        margin-left: 10px;
    }

}

.table-body {
    width: 345px;
    background: #FFFFFF;
    margin: 0 auto;

    .table-row {
        height: 50px;
    }

    .icon-img {
        width: 26px;
        height: 26px;
        margin-right: 6px;
        border-radius: 50%;
    }

    .arrow-img {
        width: 10px;
        height: 10px;
        margin-right: 3px;
    }

    .status-img {
        width: 15px;
        height: 15px;
        margin-left: 10px;
    }

    .bgc {
        background: #F7F8FF;
    }
}
</style>