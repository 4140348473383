import req from "@/utils/request";
import des from "@/utils/des";
import store from "@/store";

let request = req._axios;

// 轮播图
export const getUserInfo = (data) => {
  return request({
    url: "/api/user/index",
    method: "get",
    headers: {},
    data,
  });
};

// 获取规则
export const getRules = (data) => {
  return request({
    url: "/api/notice/rule",
    method: "post",
    headers: {},
    data,
  });
};

// 文件上传
export const uploadFile = (data) => {
  return request({
    url: "/api/common/upload",
    method: "post",
    headers: {},
    data,
  });
};

//获取充值配置信息
export const getRechargeConfig = (data) => {
  return request({
    url: "/api/payment/payInfo",
    method: "get",
    headers: {},
    data,
  });
};

//获取邀请地址
export const getInviteAddress = () => {
  return request({
    url: "/api/user/inviteqr",
    method: "get",
    headers: {},
    data: {},
  });
};

// 会员开通等级配置列表
export const getMemberUserLevel = (data) => {
  return request({
    url: "/api/payment/userLevel",
    method: "get",
    headers: {},
    data,
  });
};

//生成购买会员支付订单
export const getPayOrder = (data) => {
  return request({
    url: "/api/payment/payOrder",
    method: "post",
    headers: {},
    data,
  });
};

//查询提现手续费
export const getWithdrawalHandlingFee = (data) => {
  return request({
    url: "/api/withdraw/fee",
    method: "get",
    headers: {},
    data,
  });
};

//提现申请
export const withdrawApply = (data) => {
  return request({
    url: "/api/withdraw/apply",
    method: "post",
    headers: {},
    data,
  });
};

//我的邀请列表
export const getTeamList = (data) => {
  return request({
    url: "/api/team/index",
    method: "get",
    headers: {},
    data,
  });
};

//新增收藏
export const addCollect = (data) => {
  return request({
    url: "/api/collect/add",
    method: "post",
    headers: {},
    data,
  });
};

//取消收藏
export const cancelCollect = (address) => {
  return request({
    url: "/api/collect/cancel?btc_id=" + address,
    method: "get",
    headers: {},
    data: {},
  });
};

//我的收藏列表
export const myCollectList = (data) => {
  return request({
    url: "/api/collect/index",
    method: "get",
    headers: {},
    data,
  });
};

//帮助列表
export const getHelpList = (data) => {
  return request({
    url:
      "/api/notice/index?limit=" +
      data.limit +
      "&page=" +
      data.page +
      "&type=" +
      data.type,
    method: "get",
    headers: {},
    data,
  });
};

//帮助详情
export const getHelpDetail = (id) => {
  return request({
    url: "/api/notice/info?id=" + id,
    method: "get",
    headers: {},
    data: {},
  });
};

//支付
export const payOrder = (data) => {
  return request({
    url: "/api/payment/payOrder",
    method: "post",
    headers: {},
    data,
  });
};

//支付
export const profile = (data) => {
  return request({
    url: "/api/user/profile",
    method: "post",
    headers: {},
    data,
  });
};

//获取币种
export const getPayType = (data) => {
  return request({
    url: "/api/payment/getPayType",
    method: "get",
    headers: {},
    data,
  });
};
