<template>
    <div class="p-10">
        <div class="fr-ac sticky-title">
            <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
            <p class="bold fs-16 ml-15">{{ $t('emailPush') }}</p>
        </div>
        <div class="fc-ac mt-40">
            <el-card shadow="hover">
                <div slot="header">
                    <span>{{ $t('bindEmail') }}</span>
                </div>
                <div class="gray mb-20" v-if="myEmail">{{ $t('alreadyBound') }}：{{ myEmail }}</div>
                <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="60px">
                    <el-form-item :label="$t('email')" prop="email">
                        <el-input v-model="ruleForm.email"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <div class="pl-40">
                            <el-button type="success" @click="bind()">{{ $t('bind') }}</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </el-card>
            <el-form class="mt-30" :model="ruleForm2" status-icon label-width="130px">
                <el-form-item :label="$t('openPushNotifications')" prop="switch">
                    <el-switch v-model="ruleForm2.switch" active-color="#70ED24" @change="changeSwitch"
                        :disabled="userInfo.email ? false : true">
                    </el-switch>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { Toast } from "vant";
import { profile, getUserInfo } from '@/api/member'
export default {
    components: {

    },
    data() {
        return {
            ruleForm: {
                email: ''
            },
            rules: {
                email: [
                    { required: true, message: this.$t('inputEmail'), trigger: 'blur' },
                    { type: 'email', message: this.$t('inputValidEmail'), trigger: ['blur', 'change'] },
                ],
            },
            ruleForm2: {
                switch: false
            },
            userInfo: {},
            myEmail: ''
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        this.getUserInfo()
    },
    methods: {
        // 获取用户信息
        getUserInfo() {
            getUserInfo().then((res) => {
                if (res.data.code !== 0) {
                    this.$store.commit("login/clearToken");
                    return
                }
                this.userInfo = res.data.data?.userinfo
                this.ruleForm.email = this.userInfo.email //邮箱
                this.myEmail = res.data.data.userinfo.email
                this.ruleForm2.switch = this.userInfo.push_email == 1 ? true : false //邮箱推送状态
            });
        },
        bind() {
            let data = {
                email: this.ruleForm.email,
            }
            profile(data).then(res => {
                if (res.data.code == 0) {
                    Toast(this.$t('bindSuccess'))
                    this.getUserInfo()
                } else {
                    Toast(this.$t('bindFail'))
                }
            })
        },
        changeSwitch(e) {
            let data = {
                push_email: this.ruleForm2.switch ? 1 : 0
            }
            profile(data).then(res => {
                if (e) {
                    Toast(this.$t('switchOn'))
                } else {
                    Toast(this.$t('switchOff'))
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.title {
    font-weight: bold;
    font-size: 20px;
    color: #000000;
    margin: 50px 0;
}
</style>