<template>
    <div class="p-10">
        <div class="fr-ac">
            <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
            <p class="bold fs-16 ml-15">{{ $t('myCollection') }}</p>
        </div>
        <div class="table-head fr-ac">
            <van-col span="7">
                <div class="table-head-title">{{ $t('name') }}</div>
            </van-col>
            <van-col span="7">
                <div class="table-head-title">{{ $t('price') }}</div>
            </van-col>
            <van-col span="7">
                <div class="table-head-title1">{{ $t('marketCap') }}/{{ $t('mobility') }}</div>
            </van-col>
            <van-col span="3">
            </van-col>
        </div>
        <div class="table-body" v-if="dataList && dataList.length > 0">
            <div class="table-row fr-ac" :class="index % 2 == 1 ? 'bgc' : ''" v-for="(item, index) in dataList"
                :key="index" @click="toDetail(item.btc_id, item.view_count)">
                <van-col span="7">
                    <div class="fr-ac-jc">
                        <!-- <img class="icon-img" src="@/assets/img/head.png" alt=""> -->
                        <div class="ta-c">{{ item.name }}</div>
                    </div>
                </van-col>
                <van-col span="7">
                    <div class="fc-ac">
                        <div style="margin-bottom: 4px;">${{ Number(item.price).toFixed(9) }}</div>
                        <div class="green fs-10" :class="item.rate > 0 ? 'green' : 'red'">{{ item.rate > 0 ? '+' +
                item.rate : item.rate }}%</div>
                    </div>
                </van-col>
                <van-col span="7">
                    <div class="fc-ac">
                        <div style="margin-bottom: 4px;">${{ formattedMarketCap(item.market_price) }}</div>
                        <div class="gray fs-10">${{ formattedMarketCap(item.flow_price) }}</div>
                    </div>
                </van-col>
                <van-col span="3">
                    <div class="fc-ac" @click.stop="cancelCollect(item.btc_id, index)">
                        <img class="share-img" src="@/assets/img/collect-black.png" alt="">
                        <div class="fs-10">{{ $t('cancel') }}</div>
                    </div>
                </van-col>
            </div>
        </div>
        <div class="table-body" v-else>
            <van-empty :description="$t('noCollection')" />
        </div>
    </div>
</template>

<script>

import { myCollectList, cancelCollect } from '@/api/member'
import { Toast } from 'vant';
export default {
    components: {

    },
    data() {
        return {
            dataList: []
        }
    },
    computed: {
        formattedMarketCap(value) {
            return (value) => {
                return this.$util.formatMarketCap(Number(value) || 0);
            }
        },
    },
    watch: {

    },
    mounted() {
        this.myCollectList()
    },
    methods: {
        // 跳转详情页
        toDetail(address, view_count) {
            this.$router.push({
                path: '/mobile/detail',
                query: {
                    address,
                    view_count
                }
            })
        },
        // 获取收藏列表
        myCollectList() {
            myCollectList().then(res => {
                this.dataList = res.data.data.list
            }).catch(err => {
                console.log(err)
            })
        },
        // 取消收藏
        cancelCollect(btc_id, index) {
            cancelCollect(btc_id).then(res => {
                if (res.data.code === 0) {
                    Toast('取消成功')
                    this.dataList.splice(index, 1)
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.table-head {
    width: 345px;
    height: 55px;
    background: #F7F7F7;
    border-radius: 5px;
    margin: 21px auto 0;

    .table-head-title,
    .table-head-title1 {
        font-weight: bold;
        font-size: 16px;
        position: relative;
        text-align: center;
        z-index: 0;
    }

    .table-head-title::after,
    .table-head-title1::after {
        width: 36px;
        height: 5px;
        content: '';
        background: #70ED24;
        border-radius: 5px;
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .table-head-title1::after {
        width: 70px;
    }

    .green-circle {
        width: 9px;
        height: 9px;
        background: #70ED24;
        border-radius: 50%;
        margin-left: 10px;
    }

}

.table-body {
    width: 345px;
    background: #FFFFFF;
    margin: 0 auto;

    .table-row {
        height: 50px;
    }

    .icon-img {
        width: 26px;
        height: 26px;
        margin-right: 7px;
    }

    .share-img {
        width: 20px;
        height: 20px;
    }

    .bgc {
        background: #F7F8FF;
    }
}
</style>