<template>
    <div class="p-10">
        <div class="fr-ac">
            <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
            <p class="bold fs-16 ml-15">我的分享</p>
        </div>
        <div class="table-head fr-ac">
            <van-col span="7">
                <div class="table-head-title">名称</div>
            </van-col>
            <van-col span="7">
                <div class="table-head-title">价格</div>
            </van-col>
            <van-col span="7">
                <div class="table-head-title1">市值/流动</div>
            </van-col>
            <van-col span="3">
            </van-col>
        </div>
        <div class="table-body">
            <div class="table-row fr-ac" :class="index % 2 == 1 ? 'bgc' : ''" v-for="(item, index) in dataList"
                :key="index" @click="toDetail(item.token_contract)">
                <van-col span="7">
                    <div class="fr-ac-jc">
                        <!-- <img class="icon-img" src="@/assets/img/head.png" alt=""> -->
                        <div class="ta-c">{{ item.token_name }}</div>
                    </div>
                </van-col>
                <van-col span="7">
                    <div class="fc-ac">
                        <div style="margin-bottom: 4px;">${{ item.price.toFixed(9) }}</div>
                        <div class="green fs-10">+{{ item.price_diff.toFixed(2) }}%</div>
                    </div>
                </van-col>
                <van-col span="7">
                    <div class="fc-ac">
                        <div style="margin-bottom: 4px;">${{ formattedMarketCap(item.market_cap) }}</div>
                        <div class="gray fs-10">${{ formattedMarketCap(item.liquidity) }}</div>
                    </div>
                </van-col>
                <van-col span="3">
                    <div class="fc-ac">
                        <img class="share-img" src="@/assets/img/share-black.png" alt="">
                        <div class="fs-10">分享</div>
                    </div>
                </van-col>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    components: {

    },
    data() {
        return {
            dataList: []
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    methods: {
        // 跳转详情页
        toDetail(address) {
            this.$router.push({
                path: '/mobile/detail',
                query: {
                    address
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.table-head {
    width: 345px;
    height: 55px;
    background: #F7F7F7;
    border-radius: 5px;
    margin: 21px auto 0;

    .table-head-title,
    .table-head-title1 {
        font-weight: bold;
        font-size: 16px;
        position: relative;
        text-align: center;
        z-index: 0;
    }

    .table-head-title::after,
    .table-head-title1::after {
        width: 36px;
        height: 5px;
        content: '';
        background: #70ED24;
        border-radius: 5px;
        position: absolute;
        z-index: -1;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .table-head-title1::after {
        width: 70px;
    }

    .green-circle {
        width: 9px;
        height: 9px;
        background: #70ED24;
        border-radius: 50%;
        margin-left: 10px;
    }

}

.table-body {
    width: 345px;
    background: #FFFFFF;
    margin: 0 auto;

    .table-row {
        height: 50px;
    }

    .icon-img {
        width: 26px;
        height: 26px;
        margin-right: 7px;
    }

    .share-img {
        width: 20px;
        height: 20px;
    }

    .bgc {
        background: #F7F8FF;
    }
}
</style>