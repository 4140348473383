<template>
    <div>
        <div class="list-title green-bg">{{ type == 1 ? $t('newLive') : type == 2 ? $t('potentialCurrency') : type == 3
            ?
            $t('popularCoins') : type == 4 ? $t('tradingHotspots') : type == 5 ? $t('VIPSelection') : '' }}</div>
        <van-skeleton :row="30" :loading="loading">
            <div class="table-head fr-ac">
                <el-col :span="4">
                    <div class="table-head-title">{{ $t('name') }}</div>
                </el-col>
                <el-col :span="4">
                    <div class="table-head-title">{{ $t('price') }}</div>
                </el-col>
                <el-col :span="4">
                    <div class="table-head-title">{{ $t('marketCap') }}/{{ $t('mobility') }}</div>
                </el-col>
                <el-col :span="3">
                    <div class="table-head-title">{{ $t('txns24') }}</div>
                </el-col>
                <el-col :span="3">
                    <div class="table-head-title">{{ $t('volume24') }}</div>
                </el-col>
                <el-col :span="3">
                    <div class="table-head-title">{{ $t('holders') }}</div>
                </el-col>
                <el-col :span="3">
                    <div class="table-head-title">{{ $t('pushTime') }}</div>
                </el-col>
            </div>
            <div class="table-body">
                <div class="table-row fr-ac" v-for="(item, index) in dataList" :key="index"
                    @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1"
                    :class="{ hovered: hoverIndex === index }"
                    @click="toDetail(item.token_contract || item.address, item.view_count)">
                    <el-col :span="4">
                        <div class="fr-ac pl-20">
                            <img class="icon-img"
                                :src="item.image_icon || item.logo || require('@/assets/img/head.png')" alt="">
                            <div class="ta-c fs-18 word-break-all">{{ item.token_name || item.symbol }}
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="4" class="fc-ac">
                        <div class="fs-18 fw-b">${{ item.price.toFixed(9) }}</div>
                        <!-- <div class="fs-14 fr-ae" v-if="item.price_diff"
                                :class="item.price_diff < 0 ? 'red' : 'green'">
                                <img class="arrow-img" v-if="item.price_diff < 0" src="@/assets/img/icon_bottom_red.png"
                                    alt="">
                                <img class="arrow-img" v-else src="@/assets/img/icon_top_green.png" alt="">
                                <div class="fs-14 pos-r" style="line-height: 1;top:1px;">{{ item.price_diff.toFixed(2)
                                    }}%
                                </div>
                            </div>
                            <div class="fs-14 fr-ae" v-if="item.price_change_percent"
                                :class="item.price_change_percent < 0 ? 'red' : 'green'">
                                <img class="arrow-img" v-if="item.price_change_percent < 0"
                                    src="@/assets/img/icon_bottom_red.png" alt="">
                                <img class="arrow-img" v-else src="@/assets/img/icon_top_green.png" alt="">
                                <div class="fs-14 pos-r" style="line-height: 1;top:1px;">{{
            item.price_change_percent.toFixed(2) }}%
                                </div>
                            </div> -->
                        <div class="green-bg-box fr-ac-jc" v-if="item.price_diff"
                            :style="item.price_diff < 0 ? 'background: #FF0000;' : ''">
                            <img :class="item.price_diff < 0 ? 'rotate' : ''" class="top-icon"
                                src="@/assets/img/icon_top.png" alt="">
                            <p style="font-size: 18px;">{{ item.price_diff.toFixed(0) }}%</p>
                        </div>
                        <div class="green-bg-box fr-ac-jc" v-if="item.price_change_percent"
                            :style="item.price_change_percent < 0 ? 'background: #FF0000;' : ''">
                            <img :class="item.price_change_percent < 0 ? 'rotate' : ''" class="top-icon"
                                src="@/assets/img/icon_top.png" alt="">
                            <p style="font-size: 18px;">{{ item.price_change_percent.toFixed(0) }}%</p>
                        </div>
                    </el-col>
                    <el-col :span="4" class="fc-ac">
                        <div class="fs-18 fw-b" style="margin-bottom: 6px;">${{ formattedMarketCap(item.market_cap)
                            }}</div>
                        <div class="gray fs-18 fw-b">${{ formattedMarketCap(item.liquidity) }}</div>
                    </el-col>
                    <el-col :span="3" class="fc-ac">
                        <div class="fs-18 fw-b" style="margin-bottom: 6px;">{{ item.txcount_24h || item.swaps }}</div>
                        <div class="gray fs-18 fw-b">{{ item.buy_24h || item.buys }}/<span class="red">{{ item.sell_24h
            || item.sells }}</span>
                        </div>
                    </el-col>
                    <el-col :span="3" class="fc-ac">
                        <div class="fs-18 fw-b">${{ formattedTurnover(item.turnover_24h || item.volume) }}</div>
                    </el-col>
                    <el-col :span="3" class="fc-ac">
                        <div class="fs-18 fw-b" style="margin-bottom: 6px;">{{ item.holders || item.holder_count }}
                        </div>
                        <div class="fr-ae" v-if="item.holders_diff" :class="item.holders_diff < 0 ? 'red' : 'green'">
                            <img class="arrow-img" v-if="item.holders_diff < 0" src="@/assets/img/icon_bottom_red.png"
                                alt="">
                            <img class="arrow-img" v-else src="@/assets/img/icon_top_green.png" alt="">
                            <div class="fs-18 fw-b pos-r" style="line-height: 1;top:3px;">{{ item.holders_diff }}</div>
                        </div>
                    </el-col>
                    <el-col :span="3" class="fc-ac">
                        <div class="fs-16 fw-b gray ta-c">{{ item.launch_time }}</div>
                    </el-col>
                </div>
            </div>
        </van-skeleton>
    </div>
</template>

<script>
import { getData } from '@/api/api'
import { mapState } from 'vuex'
export default {
    data() {
        return {
            dataList: [],
            loading: true,
            interval: null,
            type: 1,
            hoverIndex: -1
        }
    },
    watch: {
        chain(newVal, oldVal) {
            this.getDataList()
        }
    },
    computed: {
        ...mapState({
            chain: state => state.login.chain
        }),
        //市值
        formattedMarketCap() {
            return (market_cap) => {
                return this.$util.formatMarketCap(Number(market_cap) || 0);
            }
        },
        //成交量24h
        formattedTurnover() {
            return (turnover) => {
                return this.$util.formatMarketCap(Number(turnover) || 0);
            }
        },
    },
    mounted() {
        this.type = Number(this.$route.query.type) || 1
        this.getDataList()
        this.createMyInterval()
    },
    beforeDestroy() {
        // 组件销毁前执行的逻辑
        this.clearMyInterval()
    },
    methods: {
        createMyInterval() {
            this.interval = setInterval(() => {
                this.getDataList()
            }, 5000);
        },
        clearMyInterval() {
            clearInterval(this.interval);
        },
        getDataList() {
            let sort = ''
            if (this.chain == 'ETH') {
                switch (this.type) {
                    case 1:
                        sort = 'ethxb'
                        break;
                    case 2:
                        sort = 'ethql'
                        break;
                    case 3:
                        sort = 'ethrm'
                        break;
                    case 4:
                        sort = 'ethrd'
                        break;
                    default:
                        break;
                }
            } else {
                switch (this.type) {
                    case 1:
                        sort = 'solxb'
                        break;
                    case 2:
                        sort = 'solql'
                        break;
                    case 3:
                        sort = 'solrm'
                        break;
                    case 4:
                        sort = 'solrd'
                        break;
                    default:
                        break;
                }
            }

            getData(sort).then((res) => {
                if (this.type == 3) {
                    //热门新币
                    this.dataList = res.data.data.rank
                    this.dataList.forEach((item) => {
                        item.launch_time = new Date(item.open_timestamp * 1000).toLocaleString()
                    })
                } else {
                    this.dataList = res.data.data
                    this.dataList.forEach((item) => {
                        item.launch_time = new Date(item.launch_time).toLocaleString()
                    })
                }
                this.loading = false
            });
        },
        // 跳转详情页
        toDetail(address, view_count) {
            this.$router.push({
                path: '/pc/detail',
                query: {
                    address,
                    view_count
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.list-title {
    font-weight: 800;
    font-size: 28px;
    margin-top: 50px;
    position: relative;
    display: inline-block;

    &.green-bg::after {
        width: 110px;
        height: 5px;
        content: '';
        background: #70ED24;
        border-radius: 5px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        z-index: -1;
    }
}

.table-head {
    width: 1280px;
    height: 68px;
    background: #000000;
    margin: 21px auto 0;
    box-shadow: 0px 0px 20px 0px rgba(42, 42, 42, 0.03);

    .table-head-title {
        position: relative;
        text-align: center;
        z-index: 0;
        font-weight: bold;
        font-size: 18px;
        color: #6CED1E;
    }

    .green-circle {
        width: 9px;
        height: 9px;
        background: #70ED24;
        border-radius: 50%;
        margin-left: 10px;
    }

}

.table-body {
    width: 1280px;
    background: #F7F7F7;
    margin: 0 auto;

    .table-row {
        height: 98px;
        box-shadow: 0px 0px 20px 0px rgba(42, 42, 42, 0.03);
        border-bottom: 1px solid #FFFFFF;
        cursor: pointer;
    }

    .hovered {
        background: #FFFFFF;
        box-shadow: 0px 0px 20px 0px rgba(42, 42, 42, 0.1);
    }

    .icon-img {
        width: 47px;
        height: 47px;
        margin-right: 15px;
        border-radius: 50%;
    }

    .arrow-img {
        width: 14px;
        height: 14px;
        margin-right: 3px;
    }

    .status-img {
        width: 15px;
        height: 15px;
        margin-left: 10px;
    }

    .bgc {
        background: #F7F8FF;
    }
}
</style>