<template>
    <div class="pb-30">
        <div class="title">{{ $t('VIPSelection') }}</div>
        <van-skeleton :row="30" :loading="loading">
            <div v-if="vipDataList && vipDataList.length > 0">
                <div class="vip-box" v-for="(item, index) in vipDataList" :key="index" @click="toDetail(item.token)">
                    <van-col span="4">
                        <img class="label-icon" src="@/assets/img/head.png" alt="">
                    </van-col>
                    <van-col span="20" class="fc-jsb" style="height: 150px;">
                        <div class="fs-16">{{ item.name }}</div>
                        <div class="mt-5">{{ item.token }}</div>
                        <!-- <div class="fr-ac mt-5">
                            <div class="mr-10" v-if="item.compare">${{ item.compare.now_price }}</div>
                            <div class="green-bg-box fr-ac-jc mr-10" style="margin-top: 0;" v-if="item.price_diff"
                                :style="item.price_diff < 0 ? 'background: #FF0000;' : ''">
                                <img :class="item.price_diff < 0 ? 'rotate' : ''" class="top-icon"
                                    src="@/assets/img/icon_top.png" alt="">
                                <p>{{ item.price_diff.toFixed(2) }}%</p>
                            </div>
                        </div> -->
                        <div class="gray mt-5">{{ $t('pushPrice') + "：" + item.push_price || '—' }}</div>
                        <div class="gray mt-5">{{ $t('pushTime') + "：" + item.add_date || '—' }}</div>
                        <div class="gray mt-5" v-if="item.day_max_profit">{{ $t('profit') + "：" + item.day_max_profit +
            'x' }}</div>
                        <div class="gray mt-5" v-else>{{ $t('profit') + "：0" }}</div>
                        <div class="gray mt-5">{{ $t('DailyHighestPrice') + "：" + item.day_max_price || '—' }}</div>
                    </van-col>
                </div>
            </div>
            <div class="table-body" v-else>
                <van-empty :description="$t('noData')" />
            </div>
        </van-skeleton>
        <van-pagination v-model="page" :total-items="total" :items-per-page="20" @change="changePage">
            <template #prev-text>
                <van-icon name="arrow-left" />
            </template>
            <template #next-text>
                <van-icon name="arrow" />
            </template>
        </van-pagination>
    </div>
</template>

<script>
import { getVipData } from '@/api/api'
import { mapState } from 'vuex'
import { Toast, Dialog } from "vant";
import { getUserInfo } from '@/api/member'
import Decimal from 'decimal.js';
export default {
    data() {
        return {
            vipDataList: [],
            loading: true,
            interval: null,
            level: 0,
            page: 1,
            total: 0,

        }
    },
    watch: {
        chain(newVal, oldVal) {
            this.getDataList()
        },
        '$store.state.login.Token': function () {
            this.getUserInfo()
        }
    },
    computed: {
        ...mapState({
            chain: state => state.login.chain
        }),
    },
    mounted() {
        this.getDataList()
        if (this.$store.state.login.Token) {
            this.getUserInfo()
        }
        this.createMyInterval()
    },
    beforeDestroy() {
        // 组件销毁前执行的逻辑
        this.clearMyInterval()
    },
    methods: {
        //创造定时器
        createMyInterval() {
            this.interval = setInterval(() => {
                this.getDataList()
            }, 5000);
        },
        //清除定时器
        clearMyInterval() {
            clearInterval(this.interval);
        },
        // 获取用户信息
        getUserInfo() {
            getUserInfo().then((res) => {
                if (res.data.code !== 0) {
                    this.$store.commit("login/clearToken");
                    return
                }
                this.level = res.data.data.userinfo.level
            });
        },
        changePage(e) {
            this.page = e
            window.scrollTo({
                top: 0,     // 垂直方向滚动到 0（顶部）
                behavior: 'smooth' // 平滑滚动
            });
            this.getDataList()
        },
        getDataList() {
            let chain = ''
            if (this.chain == 'ETH') {
                chain = 'ethvip'
            } else {
                chain = 'solvip'
            }
            getVipData(chain, 'vip', this.page, 10, '').then((res) => {
                if (this.page == 1) {
                    this.total = res.data.data.total
                }
                this.vipDataList = res.data.data.list
                this.vipDataList.forEach(item => {
                    if (item.compare) {
                        let now_price = new Decimal(item.compare.now_price);
                        let last_price = new Decimal(item.compare.last_price);
                        this.$set(item, 'price_diff', now_price.minus(last_price).dividedBy(last_price))
                    }
                })
                this.loading = false
            })
        },
        // 跳转详情页
        toDetail(address) {
            if (!this.$store.state.login.Token) {
                Dialog.confirm({
                    message: this.$t('goToLoginTip'),
                    confirmButtonColor: '#70ED24',
                    confirmButtonText: this.$t('confirm'),
                    cancelButtonText: this.$t('cancel'),
                }).then(() => {
                    this.$router.push({ path: '/mobile/login' })
                })
                return
            }
            if (this.level == 0) {
                Toast(this.$t('openVipTip'))
                return
            }
            this.$router.push({
                path: '/mobile/detail',
                query: {
                    address
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.title {
    font-weight: bold;
    font-size: 24px;
    color: #000000;
    margin: 30px 0 20px 30px;
}

.table-body {
    width: 345px;
    background: #FFFFFF;
    margin: 0 auto;

    .table-row {
        height: 50px;
    }

    .icon-img {
        width: 26px;
        height: 26px;
        margin-right: 7px;
    }

    .arrow-img {
        width: 10px;
        height: 10px;
        margin-right: 3px;
    }

    .status-img {
        width: 15px;
        height: 15px;
        margin-left: 10px;
    }

    .bgc {
        background: #F7F8FF;
    }
}

.vip-box {
    width: 345px;
    height: 170px;
    background: #FFFCFC;
    background-size: cover;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.02);
    border-radius: 7px;
    margin: 0 auto 15px;
    padding: 10px;
    font-size: 12px;

    .vip-title {
        font-weight: bold;
        font-size: 16px;
        margin-top: 6px;
    }

    .vip-chart {
        width: 32px;
        height: 18px;
        margin-left: 6px;
        margin-top: 6px;
    }
}

/deep/.van-pagination__item {
    color: #70ED24;
}

/deep/.van-pagination__item--active {
    background-color: #70ED24;
    color: #FFFFFF;
}
</style>