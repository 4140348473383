<template>
    <div class="content-box fc-ac">
        <div class="my-collect-title">{{ $t('myCollection') }}</div>
        <div class="scroll-wrap">
            <div class="table-wrap scroll-container">
                <div class="table-head fr-ac">
                    <el-col :span="6">
                        <div class="table-head-title">{{ $t('name') }}</div>
                    </el-col>
                    <el-col :span="4">
                        <div class="table-head-title">{{ $t('price') }}</div>
                    </el-col>
                    <el-col :span="4">
                        <div class="table-head-title">{{ $t('marketCap') }}/{{ $t('mobility') }}</div>
                    </el-col>
                    <el-col :span="4">
                        <div class="table-head-title">{{ $t('txns24') }}</div>
                    </el-col>
                    <el-col :span="4">
                        <div class="table-head-title">{{ $t('volume24') }}</div>
                    </el-col>
                    <el-col :span="2">

                    </el-col>
                </div>
                <div class="table-body" v-if="dataList && dataList.length > 0">
                    <div class="table-row fr-ac" v-for="(item, index) in dataList" :key="index"
                        @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1"
                        :class="{ hovered: hoverIndex === index }" @click="toDetail(item.btc_id, item.view_count)">
                        <el-col :span="6">
                            <div class="fr-ac pl-20">
                                <img class="icon-img" :src="item.logo || require('@/assets/img/head.png')" alt="">
                                <div class="ta-c fs-18">{{ item.name }}</div>
                            </div>
                        </el-col>
                        <el-col :span="4" class="fc-ac">
                            <div class="fs-18 fw-b">${{ Number(item.price).toFixed(9) }}</div>
                            <div class="green-bg-box fr-ac-jc"
                                :style="Number(item.price) < 0 ? 'background: #FF0000;' : ''">
                                <img :class="Number(item.price) < 0 ? 'rotate' : ''" class="top-icon"
                                    src="@/assets/img/icon_top.png" alt="">
                                <p style="font-size: 18px;">{{ Number(item.rate).toFixed(0) }}%</p>
                            </div>
                        </el-col>
                        <el-col :span="4" class="fc-ac">
                            <div class="fs-18 fw-b" style="margin-bottom: 6px;">${{
            formattedMarketCap(item.market_price)
        }}</div>
                            <div class="gray fs-18 fw-b">${{ formattedMarketCap(item.flow_price) }}</div>
                        </el-col>
                        <el-col :span="4" class="fc-ac">
                            <div class="fs-18 fw-b" style="margin-bottom: 6px;">{{ item.buys_h24 + item.sells_h24 }}
                            </div>
                            <div class="gray fs-18 fw-b">{{ item.buys_h24 }}/<span class="red">{{ item.sells_h24
                                    }}</span>
                            </div>
                        </el-col>
                        <el-col :span="4" class="fc-ac">
                            <div class="fs-18 fw-b">${{ formattedTurnover(item.volume_h24) }}</div>
                        </el-col>
                        <el-col :span="2" class="fc-ac">
                            <div class="fc-ac" @click.stop="cancelCollect(item.btc_id, index)">
                                <img class="share-img" src="@/assets/img/collect-black.png" alt="">
                                <div class="fs-12">{{ $t('cancel') }}</div>
                            </div>
                        </el-col>
                    </div>
                </div>
                <div class="table-body" v-else>
                    <van-empty style="margin-top: 100px;" :description="$t('noCollection')" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { myCollectList, cancelCollect } from '@/api/member'
import { Toast } from 'vant';
export default {
    components: {

    },
    data() {
        return {
            dataList: [],
            hoverIndex: -1
        }
    },
    computed: {
        ...mapGetters([
            'checkIsMobile',
        ]),
        formattedMarketCap(value) {
            return (value) => {
                return this.$util.formatMarketCap(Number(value) || 0);
            }
        },
        //成交量24h
        formattedTurnover() {
            return (turnover) => {
                return this.$util.formatMarketCap(Number(turnover) || 0);
            }
        },
    },
    watch: {

    },
    mounted() {
        this.myCollectList()
    },
    methods: {
        // 跳转详情页
        toDetail(address, view_count) {
            let path = '/detail'
            if (this.checkIsMobile) {

            }
            this.$router.push({
                path: '/detail',
                query: {
                    address,
                    view_count
                }
            })
        },
        // 获取收藏列表
        myCollectList() {
            myCollectList().then(res => {
                this.dataList = res.data.data.list
            }).catch(err => {
                console.log(err)
            })
        },
        // 取消收藏
        cancelCollect(btc_id, index) {
            cancelCollect(btc_id).then(res => {
                if (res.data.code === 0) {
                    Toast('取消成功')
                    this.dataList.splice(index, 1)
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.content-box {
    width: 860px;
    height: 907px;
    background: #2E2E2E;
    border-radius: 10px;
    position: relative;

    .my-collect-title {
        font-weight: 800;
        font-size: 18px;
        color: #70ED24;
        position: absolute;
        top: 21px;
        left: 36px;
    }

    .scroll-wrap {
        width: 840px;
        height: 832px;
        background: #FFFFFF;
        padding: 18px 0;
        margin-top: 65px;
        border-radius: 8px;
    }

    .table-wrap {
        width: 825px;
        height: 800px;
        padding: 0 22px;
        overflow: scroll;
        overflow-x: hidden;
    }
}

.table-head {
    width: 100%;
    height: 55px;
    background: #000000;
    border-radius: 5px;

    .table-head-title {
        font-weight: bold;
        font-size: 16px;
        position: relative;
        text-align: center;
        z-index: 0;
        color: #6CED1E;
    }

    .green-circle {
        width: 9px;
        height: 9px;
        background: #70ED24;
        border-radius: 50%;
        margin-left: 10px;
    }

}

.table-body {
    width: 100%;
    background: #FFFFFF;
    margin: 0 auto;

    .table-row {
        height: 98px;
        box-shadow: 0px 0px 20px 0px rgba(42, 42, 42, 0.03);
        border-bottom: 1px solid #FFFFFF;
        cursor: pointer;
        background: #F7F7F7;
    }

    .hovered {
        background: #FFFFFF;
        box-shadow: 0px 0px 20px 0px rgba(42, 42, 42, 0.1);
    }

    .icon-img {
        width: 47px;
        height: 47px;
        margin-right: 15px;
        border-radius: 50%;
    }

    .share-img {
        width: 30px;
        height: 30px;
        margin-bottom: 12px;
    }

    .bgc {
        background: #F7F8FF;
    }
}
</style>