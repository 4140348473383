<template>
    <div style="height: 73px;">
        <van-sticky :offset-top="0" z-index="101">
            <div class="fr-ac-jsb top-box">
                <img class="logo" @click="toHome" src="@/assets/img/logo.png" alt="">
                <!-- <div class="search-box fr-ac" @click="onSearch()">
                <van-icon name="search" color="#5F626B" size="20" />
                <p>{{ $t('search') }}</p>
            </div> -->
                <div class="chain-box fr-ac" @click="onChain()">
                    <p>{{ $store.state.login.chain }}</p>
                    <van-icon name="arrow-down" size="15px" color="#666666" />
                </div>
                <div class="fr-ac">
                    <!-- 切换语言 -->
                    <div class="language-box fc-ac-jc" @click="onLanguage">
                        <img v-if="languageIndex === 0" src="@/assets/img/english.png" alt="">
                        <img v-else src="@/assets/img/chinese.png" alt="">
                        <p>{{ $t('lang') }}</p>
                        <div class="language-pop fc-ac-jc" v-if="isShowLanguagePop">
                            <img v-if="languageIndex === 0" src="@/assets/img/english.png" alt="">
                            <img v-else src="@/assets/img/chinese.png" alt="">
                            <p>{{ $t('lang') }}</p>
                        </div>
                        <div class="language-pop2 fc-ac-jc" v-if="isShowLanguagePop">
                            <div class="fr-ac select-language" :class="{ 'active': languageIndex === 0 }"
                                @click="onSelectLanguage(0)">
                                <img src="@/assets/img/english.png" alt="">
                                <div class="fw-b fs-14">English</div>
                            </div>
                            <div class="fr-ac select-language" :class="{ 'active': languageIndex === 1 }"
                                @click="onSelectLanguage(1)">
                                <img src="@/assets/img/chinese.png" alt="">
                                <div class="fw-b fs-14">中文简体</div>
                            </div>
                        </div>
                    </div>
                    <!-- 钱包 -->
                    <div class="language-box fc-ac-jc" @click="showWalletPop()">
                        <img src="@/assets/img/wallet.png" alt="">
                        <p v-if="$store.state.login.Token">{{ $t("logOut") }}</p>
                        <p v-else>{{ $t("login") }}</p>
                    </div>
                </div>
            </div>
        </van-sticky>

        <!-- 钱包连接弹窗 -->
        <van-popup v-model="isShowConnectWallet" closeable round @close="closeConnectWallet()" z-index="100">
            <div class="wrapper fc-ac">
                <div class="mt-30 fw-b fs-16">Connect Wallet</div>
                <div class="fs-10 gray ta-c" style="margin-top: 7px;margin-bottom: 24px;">
                    <p>Securely connect your wallet to start</p>
                    <p>your Web3 journey</p>
                </div>
                <!-- <van-row class="wallet-box fr-ac">
          <van-col span="6">
            <img src="@/assets/img/wallet1.png" alt="">
          </van-col>
          <van-col span="10">
            <div class="fs-14 fw-b">SOLANA</div>
            <div class="light-gray fs-10">walletextenslon</div>
          </van-col>
          <van-col span="6">
            <div class="connect-btn" @click="connectWallet('solana')">Connect</div>
          </van-col>
        </van-row> -->
                <van-row class="wallet-box fr-ac">
                    <van-col span="6">
                        <img src="@/assets/img/wallet2.png" alt="">
                    </van-col>
                    <van-col span="10">
                        <div class="fs-14 fw-b">ETHEREUM</div>
                        <div class="light-gray fs-10">walletextenslon</div>
                    </van-col>
                    <van-col span="6">
                        <div class="connect-btn" @click="connectWallet('ethereum')">Connect</div>
                    </van-col>
                </van-row>
            </div>
        </van-popup>

        <!-- 链选择弹窗 -->
        <van-popup v-model="isShowChainPop" closeable round @close="closeChainPop()" z-index="100">
            <div class="wrapper fc-ac">
                <div class="mt-30 fw-b fs-16 mb-20">Choose Chain</div>
                <van-row class="wallet-box fr-ac">
                    <van-col span="6">
                        <img src="@/assets/img/wallet1.png" alt="">
                    </van-col>
                    <van-col span="10">
                        <div class="fs-14 fw-b">SOLANA</div>
                    </van-col>
                    <van-col span="6">
                        <div class="connect-btn" @click="changeChain('SOL')">Change</div>
                    </van-col>
                </van-row>
                <van-row class="wallet-box fr-ac">
                    <van-col span="6">
                        <img src="@/assets/img/wallet2.png" alt="">
                    </van-col>
                    <van-col span="10">
                        <div class="fs-14 fw-b">ETHEREUM</div>
                    </van-col>
                    <van-col span="6">
                        <div class="connect-btn" @click="changeChain('ETH')">Change</div>
                    </van-col>
                </van-row>
            </div>
        </van-popup>

        <!-- 搜索弹窗 -->
        <van-popup v-model="isShowSearch" round @close="closeSearchPop()" z-index="100">
            <div class="search-wrap">
                <van-search v-model="searchKey" placeholder="请输入完整合约地址后搜索" shape="round" show-action
                    action-text="Search" />
                <div class="search-tip">Trending of token searches</div>
                <div class="search-list fr-ac-jsb fw">
                    <div class="fr-ac search-item" v-for="item in 9" :key="item">
                        <img src="@/assets/img/head.png" alt="">
                        <div class="fs-14 fw-b">DETOX<span class="light-gray">/SOL</span></div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import util from '@/utils/util.js'
import store from "@/store";
import {
    loginAPI,
} from '@/api/login'
import i18n from '@/lang'
import web3JS from '@/utils/web3'
import des from '@/utils/des'
import { Toast, Dialog } from "vant";
import mixin from '@/utils/mixin'; //引入mixin文件
export default {
    mixins: [mixin],  // 将混入对象添加到 mixins 数组中
    data() {
        return {
            isShowLanguagePop: false, //是否显示语言弹窗
            languageIndex: 0, //语言索引
            isShowConnectWallet: false, //是否显示钱包连接弹窗
            isShowSearch: false, //是否显示搜索弹窗
            searchKey: '', //搜索关键词
            walletAddress: null, // 保存钱包地址
            web3: null, // 用来与以太坊交互的 Web3 实例
            isShowChainPop: false, //是否显示链弹窗
            chain: 'ETH', //链
        }
    },
    watch: {

    },
    mounted() {
        if (web3JS) {
            let walletAddress = web3JS.checkMetaMaskLoginStatus()
            if (!walletAddress) {
                this.walletAddress = null
                store.commit("login/clearAccount1");
                // store.commit("login/clearToken");
            }
        }
        if (this.$store.state.login.gameLang == 'CN') {
            this.languageIndex = 1
        } else {
            this.languageIndex = 0
        }
    },
    methods: {
        toHome() {
            this.$router.push('/mobile')
        },
        //打开语言弹窗
        onLanguage() {
            this.isShowLanguagePop = !this.isShowLanguagePop
        },
        //选择语言
        onSelectLanguage(index) {
            this.languageIndex = index
            if (index === 1) {
                this.$i18n.locale = 'CN'
                this.$store.commit('login/changeGameLang', 'CN')
            }
            if (index === 0) {
                this.$i18n.locale = 'EN'
                this.$store.commit('login/changeGameLang', 'EN')
            }
        },
        //打开钱包连接弹窗
        showWalletPop() {
            if (!this.$store.state.login.Token) {
                // this.isShowConnectWallet = true
                this.$router.push({ path: '/mobile/login' })
            } else {
                Dialog.confirm({
                    message: this.$t('confirmExit'),
                    confirmButtonColor: '#70ED24',
                    confirmButtonText: this.$t('confirm'),
                    cancelButtonText: this.$t('cancel'),
                }).then(() => {
                    this.$store.commit('login/clearToken')
                    // this.$store.commit('login/clearAccount1')
                }).catch(() => {

                });
            }
            // if (!this.$store.state.login.account1) {
            //     this.isShowConnectWallet = true
            // }
            // else {
            //     loginAPI(this.$store.state.login.account1).then((res) => {
            //         console.log("res.data.userinfo.token", res.data.data.userinfo.token);
            //         store.commit("login/changeToken", res.data.data.userinfo.token);
            //     });
            // }
        },
        //关闭钱包连接弹窗
        closeConnectWallet() {
            this.isShowConnectWallet = false
        },
        //关闭链弹窗
        closeChainPop() {
            this.isShowChainPop = false
        },
        //关闭搜索弹窗
        // closeSearchPop() {
        //     this.isShowSearch = false
        // },
        //打开搜索弹窗
        // onSearch() {
        //     this.isShowSearch = true
        // },
        //打开链选择弹窗
        onChain() {
            this.isShowChainPop = true
        },
        // 连接钱包
        async connectWallet() {
            // web3JS.login().then((res) => {
            //     this.walletAddress = res
            // })
            this.$router.push({ path: '/mobile/login' })
            this.isShowConnectWallet = false
        },
        //选择链
        changeChain(type) {
            this.chain = type;
            this.isShowChainPop = false
            store.commit("login/changeChain", type);
            this.$router.push('/')
        },
        handleAccountsChanged(accounts) {
            this.walletAddress = accounts[0];
            console.log('钱包切换');
            if (accounts.length > 0) {
                store.commit("login/changeAccount1", this.walletAddress);
            } else {
                store.commit("login/changeAccount1", null);
            }
            window.location.reload();
        }
    }
}
</script>

<style lang="less" scoped>
.logo {
    width: 151px;
    height: 43px;
}

.top-box {
    background-color: #FFF;
    padding: 20px 15px 4px;

    .search-box {
        background: #F7F7F7;
        border-radius: 39px;
        padding: 10px 20px 10px 10px;
        margin-left: 10px;

        p {
            font-size: 14px;
            color: #999BAA;
            margin-left: 8px;
        }
    }

    .chain-box {
        width: 74px;
        background: #202021;
        color: #70ED24;
        border: 2px solid #70ED24;
        padding: 7px 10px 7px 15px;
        border-radius: 39px;
        line-height: 1;
        margin-left: 10px;

        p {
            margin-right: 6px;
        }
    }

    .language-box {
        width: 46px;
        height: 46px;
        margin-right: 1px;
        position: relative;

        img {
            width: 25px;
            height: 25px;
        }

        p {
            font-weight: 500;
            font-size: 7px;
        }

        .language-pop {
            position: absolute;
            left: 0;
            top: 0;
            width: 46px;
            height: 46px;
            background: #fff;
            z-index: 1000;
            border-radius: 5px 5px 0 0;
            // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            // box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.35);
        }

        .language-pop2 {
            position: absolute;
            right: 0;
            top: 45px;
            width: 130px;
            height: 105px;
            background: #fff;
            border-radius: 5px 0 5px 5px;
            z-index: 999;
            box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.35);

            .select-language {
                width: 118px;
                height: 39px;
                border-radius: 5px;
                padding-left: 6px;
                margin-top: 6px;
                border-radius: 5px;

                &.active {
                    background: #EEEEEE;
                }
            }

            img {
                width: 40px;
                height: 40px;
                margin-right: 9px;
            }
        }
    }
}

// 弹窗
/deep/ .van-overlay {
    background-color: rgba(0, 0, 0, .2);
}

.wrapper {
    width: 295px;
    // height: 200px;
    background: #FFFFFF;
    box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.35);
    border-radius: 15px;
    padding-bottom: 10px;

    .wallet-box {
        width: 230px;
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        margin: 0 auto 13px;

        img {
            width: 41px;
            height: 41px;
            margin: 0 8px;
        }

        .connect-btn {
            width: 70px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            background: #000000;
            border-radius: 5px;
            font-weight: bold;
            font-size: 12px;
            color: #FFFFFF;
        }
    }
}

.search-wrap {
    width: 355px;
    // height: 314px;
    background: #FFFFFF;
    box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    padding: 22px 19px 10px;

    .search-tip {
        font-weight: bold;
        font-size: 19px;
        margin-top: 68px;
        margin-bottom: 17px;
    }

    .search-list {

        .search-item {
            width: 150px;
            height: 34px;
            background: #F2F3F7;
            border-radius: 5px;
            padding: 6px 8px;
            margin-bottom: 13px;
        }

        img {
            width: 21px;
            height: 21px;
            margin-right: 10px;
        }
    }
}
</style>