import Vue from "vue";
import VueRouter from "vue-router";
import home from "@/views/home";
import login from "@/views/login/login.vue";
import register from "@/views/login/register.vue";
import pcHome from "@/views/home/pcHome.vue"; // pc端首页
import pcNewLive from "@/views/home/pcNewLive.vue";
import vipSelection from "@/views/home/vipSelection.vue";

import detail from "@/views/detail";
import member from "@/views/member";
import withdrawal from "@/views/member/withdrawal.vue";
import myShare from "@/views/member/myShare.vue";
import myCollect from "@/views/member/myCollect.vue";
import myTeam from "@/views/member/myTeam.vue";
import helpList from "@/views/member/helpList.vue";
import helpDetail from "@/views/member/helpDetail.vue";
import openMember from "@/views/member/openMember.vue";
import noticeList from "@/views/notice";
import noticeDetail from "@/views/notice/noticeDetail.vue";
import vipIndex from "@/views/vip/index.vue";
import bindEmail from "@/views/member/bindEmail.vue";

import pcMemberIndex from "@/views/member/pcIndex.vue";
import pcMyTeam from "@/views/member/pcMyTeam.vue";
import pcMyCollect from "@/views/member/pcMyCollect.vue";
import pcHelpList from "@/views/member/pcHelpList.vue";
import pcHelpDetail from "@/views/member/pcHelpDetail.vue";
import pcNoticeIndex from "@/views/notice/pcIndex.vue";
import pcNoticeDetail from "@/views/notice/pcNoticeDetail.vue";
import pcMyOrder from "@/views/member/pcMyOrder.vue";
import pcBindEmail from "@/views/member/pcBindEmail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/mobile",
    name: "home",
    component: home,
  },
  {
    path: "/mobile/login",
    name: "login",
    component: login,
  },
  {
    path: "/mobile/register",
    name: "register",
    component: register,
  },
  {
    path: "/mobile/detail",
    name: "detail",
    component: detail,
  },
  {
    path: "/mobile/member",
    name: "member",
    component: member,
  },
  {
    path: "/mobile/withdrawal",
    name: "withdrawal",
    component: withdrawal,
  },
  {
    path: "/mobile/myShare",
    name: "myShare",
    component: myShare,
  },
  {
    path: "/mobile/myCollect",
    name: "myCollect",
    component: myCollect,
  },
  {
    path: "/mobile/myTeam",
    name: "myTeam",
    component: myTeam,
  },
  {
    path: "/mobile/helpList",
    name: "helpList",
    component: helpList,
  },
  {
    path: "/mobile/helpDetail",
    name: "helpDetail",
    component: helpDetail,
  },
  {
    path: "/mobile/openMember",
    name: "openMember",
    component: openMember,
  },
  {
    path: "/mobile/notice",
    name: "noticeList",
    component: noticeList,
  },
  {
    path: "/mobile/noticeDetail",
    name: "noticeDetail",
    component: noticeDetail,
  },
  {
    path: "/mobile/vipIndex",
    name: "vipIndex",
    component: vipIndex,
  },
  {
    path: "/mobile/bindEmail",
    name: "bindEmail",
    component: bindEmail,
  },
  {
    path: "/pc",
    name: "home",
    component: home,
    children: [
      {
        path: "",
        component: pcHome,
      },
      {
        path: "login",
        name: "login",
        component: login,
      },
      {
        path: "register",
        name: "register",
        component: register,
      },
      {
        path: "detail", // 子路由的路径，实际路径会是 '/detail'
        component: detail,
      },
      {
        path: "pcNewLive",
        component: pcNewLive,
      },
      {
        path: "pcNoticeIndex",
        component: pcNoticeIndex,
      },
      {
        path: "pcNoticeDetail",
        component: pcNoticeDetail,
      },
      {
        path: "vipSelection",
        component: vipSelection,
      },
      {
        path: "pcMemberIndex",
        component: pcMemberIndex,
        children: [
          {
            path: "",
            component: pcMyTeam,
          },
          {
            path: "pcMyCollect",
            component: pcMyCollect,
          },
          {
            path: "pcHelpList",
            component: pcHelpList,
          },
          {
            path: "pcHelpDetail",
            component: pcHelpDetail,
          },
          {
            path: "pcMyOrder",
            component: pcMyOrder,
          },
          {
            path: "pcBindEmail",
            component: pcBindEmail,
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 每次路由跳转时，页面都会滚动到顶部
    return { x: 0, y: 0 };
  },
});

// 使用路由守卫根据设备类型重定向
router.beforeEach((to, from, next) => {
  const isMobile = window.innerWidth <= 768;
  if (to.path === "/pc" && isMobile) {
    next("/mobile"); // 移动端访问PC端路由，重定向到移动端
  } else if (to.path === "/mobile" && !isMobile) {
    next("/pc"); // PC端访问移动端路由，重定向到PC端
  } else if (to.path === "/" && !isMobile) {
    next("/pc");
  } else if (to.path === "/" && isMobile) {
    next("/mobile");
  } else if (to.path.includes("/pc/") && isMobile) {
    next("/mobile"); // 移动端访问pc端路由，重定向到移动端
  } else if (to.path.includes("/mobile/") && !isMobile) {
    next("/pc"); // PC端访问移动端路由，重定向到PC端
  } else {
    next();
  }
});

export default router;
