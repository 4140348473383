<template>
    <div>
        <div class="bg-top">
            <div class="abs-top" :style="'top:' + (statusBarHeight + 10) + 'px;'">
                <div class="fr-ac">
                    <van-icon name="arrow-left" size="20px" color="#505050" @click="$router.go(-1)" />
                    <p class="bold fs-16 ml-15 white">{{ $t('openMembership') }}</p>
                </div>
            </div>
            <div class="abs-content">
                <div class="info-box fc">
                    <div class="fr-ac mb-20">
                        <img class="head-img" :src="userInfo.avatar" alt="">
                        <div class="fc">
                            <div class="fs-18 fw-b mb-10 white" v-if="userInfo.nickname">{{ userInfo.nickname }}</div>
                            <div class="green" style="width: 200px;">{{ $t('openTip') }}</div>
                        </div>
                    </div>
                    <div class="function-box">
                        <div class="function-tip">{{ $t('membershipBenefits') }}</div>
                        <div class="function-list fr-ac-jsb">
                            <div class="fc-ac" style="width: 25%;" v-for="(item, index) in functionList" :key="index">
                                <img :src="item.img" alt="">
                                <div class="ta-c">{{ item.title }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <van-swipe :show-indicators="false" :loop="false" width="110">
                    <van-swipe-item v-for="(item, index) in memberLevelList" :key="index">
                        <div class="member-type-box fc-ac-jsb" :class="selectMemberTypeIndex === index ? 'active' : ''"
                            @click="selectMemberType(index)">
                            <div class="fs-16">{{ item.name }}</div>
                            <div class="fs-14">u<span class="fs-22 fw-b">{{ item.quantity }}</span></div>
                            <!-- <div class="light-gray fc-ac">
                                <p>次月续费</p>
                                <p>可随时取消</p>
                            </div> -->
                        </div>
                    </van-swipe-item>
                </van-swipe>
                <el-form ref="form" class="mt-20">
                    <el-form-item :label="$t('selectCurrency')">
                        <el-select v-model="curCurrency" filterable :placeholder="$t('searchCurrency')">
                            <el-option v-for="item in currencyList" :key="item.currency" :label="item.currency"
                                :value="item.currency">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div class="fs-16 fw-b mt-40 mb-20" v-if="paymentRule">{{ $t('memberBenefits') }}</div>
                <div class="light-gray" style="line-height: 1.6;">
                    <div v-if="paymentRule" v-html="paymentRule"></div>
                    <!-- <p>&emsp;&emsp;确认购买后，将向您的iTunes账户收款。自动续费iTunes账户会在到期前24小时内扣费。在此之前，您可以手动在iTunes/AppleID设置管理中关闭自动续费。该项目在iPhone和iPad上均可使用。
                    </p>
                    <p>&emsp;&emsp;由知乎提供与 Apple Inc.无关购买前请仔细阅读<span class="black">《连续订阅服务协议》《会员服务协议》及《知乎支付协议》</span></p> -->
                </div>
                <!-- <div class="submit-btn" @click="openPop">提交凭证 立即开通</div> -->
                <div class="fr-ac-jsa" style="margin: 57px 0 100px;">
                    <!-- authorize -->
                    <!-- <van-button type="default" @click="switchToMainnet">{{ $t('authorizationContract') }}</van-button> -->
                    <van-button type="default" @click="payment">{{ $t('pay') }}</van-button>
                </div>
            </div>

            <!-- 凭证上传弹窗 -->
            <!-- <van-popup v-model="isShowPop" round @close="closePop()" z-index="100">
                <div class="">
                    <div class="fr-ac-jsb pop-top">
                        <div class="fs-16 fw-b green">上传凭证</div>
                        <van-icon name="cross" color="#70ED24" size="20" @click="closePop()" />
                    </div>
                    <div class="pop-center">
                        <div class="fr-ac-jsb">
                            <div class="member-type-box fc-ac-jsb active">
                                <div class="fs-16">{{ MemberLevel.name }}</div>
                                <div class="fs-14">u<span class="fs-22 fw-b">{{ MemberLevel.quantity }}</span></div>
                                <div class="light-gray fc-ac">
                                    <p>次月续费</p>
                                    <p>可随时取消</p>
                                </div>
                            </div>
                            <img class="arrow-right" src="@/assets/img/arrow-right-black.png" alt="">
                            <van-uploader :after-read="afterRead" v-model="fileList" :max-count="1">
                                <div class="upload-box fc-ac-jc">
                                    <img src="@/assets/img/icon-add.png" alt="">
                                    <div>上传截图凭证</div>
                                </div>
                            </van-uploader>
                        </div>
                        <div class="submit-btn" @click="submit()">提交凭证</div>
                    </div>
                </div>
            </van-popup> -->

            <!-- 开通vip弹窗 -->
            <el-dialog width="300px" :title="$t('paymentCode')" :visible.sync="showImgCode">
                <div class="fc">
                    <div class="mb-10">{{ $t('selectCurrency') }}: {{ curCurrency }} </div>
                    <div class="mb-10 fr-ac">
                        <div class="mr-10">{{ $t('paymentAmount') }}: {{ payPrice }}{{ pay_currency }} </div>
                        <img @click="copy(payPrice)" class="pointer" src="@/assets/img/icon_copy.png" alt="">
                    </div>
                    <div class="mb-10 fr-ac">
                        <div class="mr-10">{{ $t('paymentAddress') }}: {{ payAddress }} </div>
                        <img @click="copy(payAddress)" class="pointer" src="@/assets/img/icon_copy.png" alt="">
                    </div>
                </div>
                <img style="width: 100%;height: auto;" :src="imgCode" alt="">
            </el-dialog>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util.js'
import { uploadFile, getMemberUserLevel, getPayOrder, getRechargeConfig, getUserInfo, getRules, payOrder, getPayType } from '@/api/member'
import { Toast, Dialog } from "vant"
import Web3 from '@/utils/web3.min'
import getContract from '@/utils/contract'
export default {
    components: {

    },
    data() {
        return {
            statusBarHeight: '',
            functionList: [{
                img: require('@/assets/img/member-fun1.png'),
                title: this.$t('highQualityColumn')
            }, {
                img: require('@/assets/img/member-fun2.png'),
                title: this.$t('newCoinRecommendation')
            }, {
                img: require('@/assets/img/member-fun3.png'),
                title: this.$t('exclusiveCustomerService')
            }, {
                img: require('@/assets/img/member-fun4.png'),
                title: this.$t('greatValueDiscounts')
            },],
            selectMemberTypeIndex: 0,
            isShowPop: false,
            fileList: [], //上传图片
            fileUrl: '', //服务端返回的图片地址
            memberLevelList: [], //会员等级列表
            MemberLevel: {}, //选择的会员等级
            contractABI: [], //合约abi
            contractAddress: '',//合约地址,
            spenderAddress: '',//需要收钱的合约地址,
            web3: '',//web3实例
            contract: null, //合约实例
            isPay: false, //是否可以支付
            userInfo: {}, //用户信息
            paymentRule: '', //支付规则
            imgCode: '',//支付二维码
            payAddress: '',//支付地址
            payPrice: '',//支付金额
            pay_currency: '', //支付币种
            showImgCode: false, //支付码弹窗
            debounceTimeout: null, //防抖
            currencyList: [], //币种列表
            curCurrency: 'sol', //选择的币种
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        this.statusBarHeight = util.getStatusBarHeight()
        this.getMemberUserLevel()
        this.contractABI = getContract.getContractABI()
        this.contractAddress = getContract.getContractAddress()
        this.getRechargeConfig()
        this.getUserInfo()
        this.getRules()
        this.getPayType()
    },
    methods: {
        //获取会员权益
        getRules() {
            let data = {
                type: 'payment_rule'
            }
            getRules(data).then((res) => {
                this.paymentRule = res.data.data
            })
        },
        //获取币种
        getPayType() {
            getPayType().then((res) => {
                this.currencyList = res.data.data
            });
        },
        // 获取用户信息
        getUserInfo() {
            getUserInfo().then((res) => {
                if (res.data.code !== 0) {
                    this.$store.commit("login/clearToken");
                    return
                }
                this.userInfo = res.data.data.userinfo
            });
        },
        //获取会员等级
        getMemberUserLevel() {
            getMemberUserLevel().then((res) => {
                this.memberLevelList = res.data.data
                this.MemberLevel = this.memberLevelList[0]
                this.selectMemberTypeIndex = 0
            });
        },
        //获取充值配置
        getRechargeConfig() {
            getRechargeConfig().then((res) => {
                this.spenderAddress = res.data.data.main_contract_address
            });
        },
        //选择会员等级
        selectMemberType(index) {
            this.selectMemberTypeIndex = index
            this.MemberLevel = this.memberLevelList[index]
        },
        openPop() {
            this.isShowPop = true
        },
        closePop() {
            this.isShowPop = false
        },
        //文件上传
        afterRead(file) {
            // 此时可以自行将文件上传至服务器
            console.log(file.file);
            const formData = new FormData();
            formData.append('file', file.file);
            uploadFile(formData).then((res) => {
                this.fileUrl = res.data.data.url
            });
        },
        //提交凭证
        // submit() {
        //     if (this.fileUrl == '') {
        //         Toast("请上传凭证");
        //         return
        //     } else {
        //         let data = {
        //             level_id: this.MemberLevel.id,
        //             file: this.fileUrl
        //         }
        //         getPayOrder(data).then((res) => {
        //             if (res.data.code === 0) {
        //                 this.isShowPop = false
        //                 Toast('提交成功');
        //             }
        //         });
        //     }
        // },
        //授权合约
        async authorize() {
            if (window.ethereum) {
                this.web3 = new Web3(window.ethereum);  // 初始化 Web3 实例
                try {
                    // 请求用户授权钱包
                    this.contract = new this.web3.eth.Contract(this.contractABI, this.contractAddress);  // 初始化合约实例
                    if (this.$store.state.login.account1) {
                        const amount = this.web3.utils.toWei(String(this.MemberLevel.quantity), 'ether'); // 授权代币
                        try {
                            await this.contract.methods.approve(this.spenderAddress, amount)
                                .send({ from: this.$store.state.login.account1 })
                                .on('transactionHash', (hash) => {
                                    this.getBalance(amount)
                                })
                                .on('receipt', (receipt) => {
                                    // Toast(this.$t('transactionConfirmed'));
                                    console.log('Receipt:', receipt);
                                })
                                .on('error', (error) => {
                                    // Toast(this.$t('transactionFailed'));
                                    console.error('Error:', error.message);
                                });
                        } catch (error) {
                            Toast(this.$t('transactionFailed'));
                            console.error('Error:', error);
                        }
                    }
                } catch (error) {
                    Toast(this.$t('authorizationFailed'));
                }
            } else {
                Toast(this.$t('installMetaMask'));
            }
        },
        //判断是否连接到以太坊链，并请求用户切换到以太坊主网
        async switchToMainnet() {
            if (window.ethereum) {
                try {
                    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
                    // 如果用户不在主网，提示切换网络
                    if (chainId !== '0x1') {
                        Dialog.confirm({
                            message: this.$t('changeChainTip'),
                            confirmButtonText: this.$t('confirm'),
                            cancelButtonText: this.$t('cancel'),
                            confirmButtonColor: '#70ED24',
                        }).then(async () => {
                            // 请求切换到以太坊主网（链ID: 0x1）
                            await window.ethereum.request({
                                method: 'wallet_switchEthereumChain',
                                params: [{ chainId: '0x1' }],
                            });
                            Toast(this.$t('switchingSuccessful'));
                            this.authorize()
                        }).catch(() => {
                            console.log('用户取消了切换网络');
                        });
                        console.log('正在切换到以太坊主网...');
                    } else {
                        this.authorize()
                        console.log('您已经连接到以太坊主网');
                    }
                } catch (error) {
                    if (error.code === 4902) {
                        Toast(this.$t('noChainChange'));
                        console.log('目标网络尚未添加，请添加以太坊主网');
                    } else {
                        console.error('切换网络失败:', error);
                    }
                }
            } else {
                Toast(this.$t('installMetaMask'));
            }
        },
        // 获取用户的 USDT 余额
        async getBalance(amount) {
            try {
                const balanceWei = await this.contract.methods.balanceOf(this.$store.state.login.account1).call();
                // 将 USDT 转换为浮动数值（小数点后有 6 位）
                let balance = this.web3.utils.fromWei(balanceWei, 'mwei');
                if (Number(balance) >= Number(amount) / 1000000) {
                    Toast(this.$t('authorizationSuccessful'));
                    this.isPay = true
                } else {
                    Toast(this.$t('insufficientBalance'));
                }
            } catch (error) {
                console.error('获取余额失败:', error);
                Toast(this.$t('failedObtainBalance'));
            }
        },
        //支付
        async payment() {
            if (!this.curCurrency) {
                Toast(this.$t('pleaseSelectCurrency'));
                return
            }
            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout);
            }
            let data = {
                level_id: this.MemberLevel.id,
                btc_type: this.curCurrency
            }
            this.debounceTimeout = setTimeout(() => {
                payOrder(data).then((res) => {
                    this.imgCode = res.data.data.img;
                    this.payAddress = res.data.data.address;
                    this.payPrice = res.data.data.pay_amount;
                    this.pay_currency = res.data.data.pay_currency;
                    this.showImgCode = true
                });
            }, 1000);  // 1000ms 防抖延迟

            // if (this.$store.state.login.account1) {
            //     console.log('this.$store.state.login.account1', this.$store.state.login.account1);
            //     const amount = this.web3.utils.toWei(String(this.MemberLevel.quantity), 'ether'); // 授权代币
            //     try {
            //         await this.contract.methods.transfer(this.spenderAddress, amount)
            //             .send({ from: this.$store.state.login.account1 })
            //             .on('transactionHash', (hash) => {
            //                 Toast(this.$t('transactionSent'));
            //                 console.log('Transaction Hash:', hash);
            //                 this.isPay = false
            //                 this.submitHash(hash)
            //             })
            //             .on('receipt', (receipt) => {
            //                 Toast(this.$t('transactionConfirmed'));
            //                 this.isPay = false
            //                 console.log('Receipt:', receipt);
            //             })
            //             .on('error', (error) => {
            //                 Toast(this.$t('transactionFailed'));
            //                 this.isPay = false
            //                 console.error('Error:', error.message);
            //             });
            //     } catch (error) {
            //         Toast(this.$t('transactionFailed'));
            //         console.error('Error:', error);
            //     }
            // }
        },
        submitHash(hash) {
            let data = {
                level_id: this.MemberLevel.id,
                hash
            }
            getPayOrder(data).then((res) => {
                if (res.data.code === 0) {
                    console.log('res', res);
                }
            });
        },
        async copy(copy) {
            // 创建一个隐藏的 textarea 元素
            const textArea = document.createElement('textarea');
            textArea.value = copy;
            document.body.appendChild(textArea);

            // 选择文本
            textArea.select();
            textArea.setSelectionRange(0, 99999);  // 对于移动设备需要设置范围

            // 执行复制操作
            try {
                document.execCommand('copy');
                Toast(this.$t('copySuccess'));
            } catch (err) {
                console.error('Failed to copy text: ', err);
            }

            // 移除临时的 textarea 元素
            document.body.removeChild(textArea);
        }
    }
}
</script>

<style lang="less" scoped>
body {
    background-color: #F5F6F8;
}

.bg-top {
    background-image: url('@/assets/img/member-bg.png');
    background-size: cover;
    width: 100%;
    height: 251px;
}

.abs-top {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 0 10px;
}

.abs-content {
    position: absolute;
    top: 103px;
    left: 15px;
    right: 15px;

    .info-box {
        margin-bottom: 26px;

        .head-img {
            width: 61px;
            height: 61px;
            margin-right: 10px;
            border-radius: 50%;
        }

        .function-box {
            width: 100%;
            background: #70ED24;
            border-radius: 15px 15px 20px 20px;

            .function-tip {
                padding: 12px 17px 10px;
            }

            .function-list {
                width: 100%;
                background: #F5F6F8;
                border-radius: 15px;
                padding: 18px 10px;

                img {
                    width: 40px;
                    height: 40px;
                    margin-bottom: 7px;
                }
            }
        }
    }
}

.member-type-box {
    width: 101px;
    height: 120px;
    border-radius: 15px 25px 15px 15px;
    border: 3px solid #999999;
    box-sizing: border-box;
    padding: 26px 0 20px;

    &.active {
        border: 3px solid #70ED24;
        background: #202021;
        color: #70ED24;
    }
}

.submit-btn {
    width: 100%;
    height: 49px;
    line-height: 49px;
    text-align: center;
    background: #202021;
    font-size: 14px;
    color: #70ED24;
    margin: 57px 0 30px;
}


.pop-top {
    width: 355px;
    padding: 18px 16px;
    background: #000000;
}

.pop-center {
    width: 355px;
    padding: 47px 30px 0;

    .arrow-right {
        width: 45px;
        height: 45px;
    }

    .upload-box {
        width: 105px;
        height: 150px;
        background: #EEEEEE;
        border-radius: 15px;

        img {
            width: 25px;
            height: 25px;
            margin-bottom: 22px;
        }
    }
}

/deep/.van-uploader__preview-image {
    width: 100px;
    height: 147px;
}

//按钮
/deep/.van-button--default {
    color: #70ED24;
    background-color: #202021;
    padding: 0 50px;
    border-radius: 5px;
}

//选择款聚焦样式
/deep/.el-select .el-input.is-focus .el-input__inner {
    border-color: #70ED24;
}

/deep/.el-select .el-input__inner:focus {
    border-color: #70ED24;
}
</style>