<template>
    <div class="p-10">
        <div class="fr-ac">
            <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
            <p class="bold fs-16 ml-15">{{ $t('wantWithdrawal') }}</p>
        </div>
        <div class="wallet-box">
            <!-- <div class="fr-ac-jc explain-box" @click="isShowPop = true">
                <van-icon name="question-o" size="16px" color="#70ED24" />
                <div style="line-height: 1;margin-left: 5px">{{ $t('withdrawalInstructions') }}</div>
            </div> -->
            <div class="info-box fc">
                <div style="margin-bottom: 4px;"><span class="fs-16">{{ $t('accountBalance') }}</span>（{{
                $t('withdrawable') }}）</div>
                <div class="fs-24 fw-b">{{ money }} <span class="fs-14" style="margin-left: 6px;">USTD</span></div>
            </div>
            <div class="input-box">
                <p>{{ $t('withdrawTip') }}<span v-if="minMoney">({{ $t('minimum') + minMoney }})</span></p>
                <van-cell-group>
                    <van-field type="number" v-model="inputMoney"
                        :placeholder="$t('remaining') + ' ' + money + 'USTD ' + $t('withdrawable')" />
                </van-cell-group>
                <p class="mt-20">{{ $t('withdrawalAddressTip') }}</p>
                <van-cell-group>
                    <van-field v-model="inputAddress" :placeholder="$t('withdrawalAddressTip')" />
                </van-cell-group>
                <p class="mt-20">{{ $t('selectCurrency') }}</p>
                <el-select v-model="curCurrency" filterable :placeholder="$t('searchCurrency')">
                    <el-option v-for="item in currencyList" :key="item.currency" :label="item.currency"
                        :value="item.currency">
                    </el-option>
                </el-select>
                <div class="withdrawal-btn" @click="withdrawal()">{{ $t('withdrawalBtn') }}({{
                $t('serviceCharge') + ' ' + fee }}%)</div>
            </div>
        </div>
        <div class="p-10">
            <p class="fs-14 fw-b">{{ $t('notice') }}：</p>
            <div class="tip-box">
                <div v-if="withdrawalInstructions" v-html="withdrawalInstructions"></div>
                <!-- <p>1.京东集团定位于“以供应链为基础的技术与服务企业”。 </p>
                <p>2.目前业务已涉及零售、科技、物流、健康、产发、工业、自有品牌、保险和国际等领域。</p>
                <p>3.作为同时具备实体企业基因和属性、拥有数字技术和能力的新型实体企业。</p>
                <p>4.京东集团依托“有责任的供应链”，持续推进“链网融合实现了货网、仓网、云网的“三网通。</p>
                <p>5.不仅保障自身供应链稳定可靠，也带动产业链上下游合作伙伴数字化转型和降本增效，更好服务实发展。</p> -->
            </div>
        </div>
        <!-- 提现说明弹窗 -->
        <!-- <van-popup v-model="isShowPop" round @close="closePop()" z-index="100">
            <div class="">
                <div class="fr-ac-jsb pop-top">
                    <div class="fs-16 fw-b green">提现说明</div>
                    <van-icon name="cross" color="#70ED24" size="20" @click="closePop()" />
                </div>
                <div class="pop-center">
                    <div v-if="withdrawalInstructions" v-html="withdrawalInstructions"></div>
                </div>
            </div>
        </van-popup> -->
    </div>
</template>

<script>
import store from "@/store";
import { getWithdrawalHandlingFee, withdrawApply, getRules, getPayType } from '@/api/member'
import i18n from '@/lang'
import { Toast, Dialog } from "vant"
export default {
    components: {

    },
    data() {
        return {
            inputMoney: '',
            inputAddress: '',
            money: '',
            fee: null,//手续费
            minMoney: null,//最低提现金额
            isShowPop: false,//提现说明弹窗
            withdrawalInstructions: '',//提现说明
            currencyList: [], //币种列表
            curCurrency: 'sol', //选择的币种
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
        this.money = this.$route.query.money
        this.getWithdrawalHandlingFee()
        this.getRules()
        this.getPayType()
    },
    methods: {
        //获取币种
        getPayType() {
            getPayType().then((res) => {
                this.currencyList = res.data.data
            });
        },
        //获取提现手续费
        getWithdrawalHandlingFee() {
            getWithdrawalHandlingFee().then(res => {
                this.fee = res.data.data.fee
                this.minMoney = Number(res.data.data.min) || 0
            })
        },
        withdrawal() {
            if (!this.inputMoney) {
                Toast(this.$t('inputWwithdrawalAmount'))
                return
            } else if (this.inputMoney < this.minMoney) {
                Toast(this.$t('withdrawalCannotLess') + this.minMoney)
                return
            } else if (!this.inputAddress) {
                Toast(this.$t('withdrawalAddressTip'))
                return
            } else if (!this.curCurrency) {
                Toast(this.$t('pleaseSelectCurrency'))
                return
            }
            Dialog.confirm({
                message: this.$t('confirmWithdrawalAddress') + this.inputAddress,
                confirmButtonText: this.$t('confirm'),
                cancelButtonText: this.$t('cancel'),
                confirmButtonColor: '#70ED24',
            }).then(async () => {
                let data = {
                    amount: this.inputMoney,
                    address: this.inputAddress,
                    btc_type: this.curCurrency
                }
                withdrawApply(data).then(res => {
                    if (res.data.code === 0) {
                        Toast(this.$t('withdrawalSuccessful'))
                        this.$router.go(-1)
                    } else {
                        Toast(res.data.msg)
                    }
                })
            })
        },
        //获取提现说明
        getRules() {
            let data = {
                type: 'withourt_rule'
            }
            getRules(data).then((res) => {
                this.withdrawalInstructions = res.data.data
            })
        },
        closePop() {
            this.isShowPop = false
        }
    }
}
</script>

<style lang="less" scoped>
.wallet-box {
    width: 100%;
    background: #2f2f2f;
    border-radius: 20px;
    margin-top: 27px;
    position: relative;
    padding: 6px;
    margin-bottom: 33px;

    .explain-box {
        position: absolute;
        right: 0;
        top: 0;
        width: 100px;
        height: 40px;
        background: #423E3E;
        border-radius: 0px 20px 0px 20px;
        color: #70ED24;
    }

    .info-box {
        padding: 28px 0 23px 20px;
        color: #FFF;
    }

    .input-box {
        width: 100%;
        background: #FFFFFF;
        border-radius: 0px 0px 15px 15px;
        padding: 35px 20px 20px;

        p {
            font-weight: bold;
            font-size: 16px;
            color: #202020;
            margin-bottom: 20px;
        }

        .withdrawal-btn {
            width: 308px;
            height: 41px;
            line-height: 41px;
            text-align: center;
            background: #202021;
            font-size: 14px;
            color: #70ED24;
            margin-top: 18px;
        }
    }
}

.tip-box {
    font-size: 12px;
    color: #333333;
    line-height: 2;
    margin-top: 16px;
}

.pop-top {
    width: 355px;
    padding: 18px 16px;
    background: #000000;
}

.pop-center {
    width: 355px;
    padding: 47px 30px 30px;
}
</style>