<template>
    <div>
        <div class="list-title green-bg">{{ $t('VIPSelection') }}</div>
        <van-skeleton :row="30" :loading="loading">
            <div class="" v-if="vipDataList && vipDataList.length > 0">
                <div class="table-head fr-ac">
                    <el-col :span="8">
                        <div class="table-head-title">{{ $t('name') }}</div>
                    </el-col>
                    <el-col :span="8">
                        <div class="table-head-title">{{ $t('contractCode') }}</div>
                    </el-col>
                    <!-- <el-col :span="6">
                        <div class="table-head-title">{{ $t('price') }}</div>
                    </el-col> -->
                    <el-col :span="4">
                        <div class="table-head-title">{{ $t('pushPrice') }}</div>
                    </el-col>
                    <el-col :span="4">
                        <div class="table-head-title">{{ $t('pushTime') }}</div>
                    </el-col>
                    <el-col :span="4">
                        <div class="table-head-title">{{ $t('profit') }}</div>
                    </el-col>
                    <el-col :span="4">
                        <div class="table-head-title">{{ $t('DailyHighestPrice') }}</div>
                    </el-col>
                </div>
                <div class="table-body">
                    <div class="table-row fr-ac" v-for="(item, index) in vipDataList" :key="index"
                        @mouseenter="hoverIndex = index" @mouseleave="hoverIndex = -1"
                        :class="{ hovered: hoverIndex === index }" @click="toDetail(item.token)">
                        <el-col :span="8">
                            <div class="fr-ac pl-20">
                                <img class="icon-img" src="@/assets/img/head.png" alt="">
                                <div class="ta-c fs-18 word-break-all">{{ item.name }}</div>
                            </div>
                        </el-col>
                        <el-col :span="8">
                            <div class="ta-c fs-14">{{ item.token }}</div>
                        </el-col>
                        <!-- <el-col :span="6">
                            <div class="fc-ac">
                                <div class="fs-18 fw-b" v-if="item.compare">${{ item.compare.now_price }}</div>
                                <div class="fs-18 fw-b" v-else>—</div>
                                <div class="green-bg-box fr-ac-jc" v-if="item.price_diff"
                                    :style="item.price_diff < 0 ? 'background: #FF0000;' : ''">
                                    <img :class="item.price_diff < 0 ? 'rotate' : ''" class="top-icon"
                                        src="@/assets/img/icon_top.png" alt="">
                                    <p style="font-size: 18px;">{{ item.price_diff.toFixed(2) }}%</p>
                                </div>
                            </div>
                        </el-col> -->
                        <el-col :span="4">
                            <div class="ta-c fs-18 gray word-break-all">{{ item.push_price || '—' }}</div>
                        </el-col>
                        <el-col :span="4">
                            <div class="ta-c fs-18 gray">{{ item.add_date }}</div>
                        </el-col>
                        <el-col :span="4">
                            <div class="ta-c fs-18 gray word-break-all">{{ item.day_max_profit ? item.day_max_profit +
            'x' : '—' }}
                            </div>
                        </el-col>
                        <el-col :span="4">
                            <div class="ta-c fs-18 gray word-break-all">{{ item.day_max_price || '—' }}</div>
                        </el-col>
                    </div>
                </div>
            </div>
            <div class="table-body" v-else>
                <van-empty :description="$t('noData')" />
            </div>
        </van-skeleton>
        <div class="fr-ac-jc mt-20">
            <el-pagination :current-page="page" :page-size="20" background layout="prev, pager, next" :total="total"
                hide-on-single-page @current-change="handleCurrentChange">
            </el-pagination>
        </div>

    </div>
</template>

<script>
import { getVipData } from '@/api/api'
import { mapState } from 'vuex'
import { Toast } from "vant";
import { getUserInfo } from '@/api/member'
import Decimal from 'decimal.js';
export default {
    data() {
        return {
            vipDataList: [],
            loading: true,
            interval: null,
            level: 0,
            hoverIndex: -1,
            total: 0, // 总条数
            page: 1, // 当前页
        }
    },
    watch: {
        chain(newVal, oldVal) {
            this.page = 1
            this.getDataList()
        },
        '$store.state.login.Token': function () {
            this.getUserInfo()
            this.getDataList()
        }
    },
    computed: {
        ...mapState({
            chain: state => state.login.chain
        }),
    },
    mounted() {
        this.getDataList()
        if (this.$store.state.login.Token) {
            this.getUserInfo()
        }
        this.createMyInterval()
    },
    beforeDestroy() {
        // 组件销毁前执行的逻辑
        this.clearMyInterval()
    },
    methods: {
        //创造定时器
        createMyInterval() {
            this.interval = setInterval(() => {
                this.getDataList()
            }, 5000);
        },
        //清除定时器
        clearMyInterval() {
            clearInterval(this.interval);
        },
        // 获取用户信息
        getUserInfo() {
            getUserInfo().then((res) => {
                if (res.data.code !== 0) {
                    this.$store.commit("login/clearToken");
                    return
                }
                this.level = res.data.data.userinfo.level
            });
        },
        handleCurrentChange(e) {
            this.page = e
            window.scrollTo({
                top: 0,     // 垂直方向滚动到 0（顶部）
                behavior: 'smooth' // 平滑滚动
            });
            this.getDataList()
        },
        getDataList() {
            let chain = ''
            if (this.chain == 'ETH') {
                chain = 'ethvip'
            } else {
                chain = 'solvip'
            }
            getVipData(chain, 'vip', this.page, 10, '').then((res) => {
                if (this.page == 1) {
                    this.total = res.data.data.total
                }
                this.vipDataList = res.data.data.list
                this.vipDataList.forEach(item => {
                    if (item.compare) {
                        let now_price = new Decimal(item.compare.now_price);
                        let last_price = new Decimal(item.compare.last_price);
                        this.$set(item, 'price_diff', now_price.minus(last_price).dividedBy(last_price))
                    }
                })
                this.loading = false
            })
        },
        // 跳转详情页
        toDetail(address) {
            if (!this.$store.state.login.Token) {
                this.$confirm(this.$t('goToLoginTip'), {
                    confirmButtonText: this.$t('confirm'),
                    cancelButtonText: this.$t('cancel'),
                }).then(() => {
                    this.$router.push({ path: '/pc/login' })
                })
                return
            }
            if (this.level == 0) {
                Toast(this.$t('openVipTip'))
                return
            }
            this.$router.push({
                path: '/pc/detail',
                query: {
                    address
                }
            })
        },
    }
}
</script>

<style lang="less" scoped>
.list-title {
    font-weight: 800;
    font-size: 28px;
    margin-top: 50px;
    position: relative;
    display: inline-block;

    &.green-bg::after {
        width: 110px;
        height: 5px;
        content: '';
        background: #70ED24;
        border-radius: 5px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        z-index: -1;
    }
}

.table-head {
    width: 1280px;
    height: 68px;
    background: #000000;
    margin: 21px auto 0;
    box-shadow: 0px 0px 20px 0px rgba(42, 42, 42, 0.03);

    .table-head-title {
        position: relative;
        text-align: center;
        z-index: 0;
        font-weight: bold;
        font-size: 18px;
        color: #6CED1E;
    }

    .green-circle {
        width: 9px;
        height: 9px;
        background: #70ED24;
        border-radius: 50%;
        margin-left: 10px;
    }

}

.table-body {
    width: 1280px;
    background: #F7F7F7;
    margin: 0 auto;

    .table-row {
        height: 98px;
        box-shadow: 0px 0px 20px 0px rgba(42, 42, 42, 0.03);
        border-bottom: 1px solid #FFFFFF;
        cursor: pointer;
    }

    .hovered {
        background: #FFFFFF;
        box-shadow: 0px 0px 20px 0px rgba(42, 42, 42, 0.1);
    }

    .icon-img {
        width: 47px;
        height: 47px;
        margin-right: 15px;
        border-radius: 50%;
    }

    .arrow-img {
        width: 14px;
        height: 14px;
        margin-right: 3px;
    }

    .status-img {
        width: 15px;
        height: 15px;
        margin-left: 10px;
    }

    .bgc {
        background: #F7F8FF;
    }
}

/deep/.el-pagination button {
    height: 46px !important;
    line-height: 46px !important;
    width: 46px !important;
}

/deep/.el-pager li {
    height: 46px !important;
    line-height: 46px !important;
    width: 46px !important;
}

/deep/.el-pager li:hover {
    color: #70ED24 !important;
}

/deep/.el-pagination.is-background .el-pager li.active {
    background-color: #70ED24 !important;
}
</style>