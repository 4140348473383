<template>
    <div class="fr-ac-jsb" style="margin: 100px 100px 80px;">
        <div class="fc-ac">
            <img class="logo" style="margin-bottom: 20px;" src="@/assets/img/logo.png" alt="">
            <!-- <div class="tip" style="font-size: 24px;font-weight: bold;">- {{ $t('riskTip') }} -</div> -->
        </div>
        <div class="logo-icon-box">
            <img class="logo-icon" src="@/assets/img/logo-icon1.png" alt="">
            <img class="logo-icon" src="@/assets/img/logo-icon2.png" alt="">
            <img class="logo-icon" src="@/assets/img/logo-icon3.png" alt="">
            <img class="logo-icon" src="@/assets/img/logo-icon4.png" alt="">
            <img class="logo-icon" src="@/assets/img/logo-icon5.png" alt="">
            <img class="logo-icon" src="@/assets/img/logo-icon6.png" alt="">
        </div>
        <!-- <img class="code" style="margin-right: 18px;" src="@/assets/img/code.png" alt="">
            <img class="advertisement" src="@/assets/img/advertisement.png" alt=""> -->
    </div>
</template>

<script>
import store from "@/store";
import web3JS from '@/utils/web3'
export default {
    data() {
        return {

        }
    },
    watch: {

    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
.logo-icon-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
}

.logo-icon {
    width: 64px;
    height: 64px;
}
</style>