export default {
  lang: "中文",
  search: "搜索",
  searchPlaceholder: "请输入完整的合约地址",
  wallet: "钱包",
  walletLogin: "钱包登录",
  login: "登录",
  logined: "已登录",
  logOut: "登出",
  home: "首页",
  consulting: "资讯",
  memberCenter: "会员中心",
  memberCenter2: "会员中心",
  newLive: "新币直播",
  potentialCurrency: "潜力新币",
  popularCoins: "热门新币",
  tradingHotspots: "交易热点",
  VIPSelection: "VIP精选",
  profitStatistics: "盈利统计",
  more: "更多",
  checkMore: "查看更多",
  marketCap: "市值",
  mobility: "流动性",
  txns: "交易笔数",
  holders: "持有人数",
  volume: "交易量",
  buys: "购买",
  sells: "出售",
  txns24: "24h交易数",
  volume24: "24h成交量",
  dataInformation: "数据信息",
  collectionSuccessful: "收藏成功",
  shareSuccessful: "分享链接已复制到剪贴板",
  contractCode: "合约代码",
  membershipDescription: "会员说明",
  membershipExpiration: "会员到期",
  upgradeMembership: "升级会员",
  accountBalance: "账户余额",
  withdrawable: "可提现",
  withdrawal: "我要提现",
  accumulatedIncome: "累计收益",
  pendingReview: "待审核提现",
  myTeam: "我的团队",
  inviteFriends: "邀请好友",
  inviteToEarnProfits: "邀请好友赚收益",
  myCollection: "我的收藏",
  helpCenter: "帮助中心",
  openMembership: "开通会员",
  openTip: "开通平台会员，畅享会员好内容",
  membershipBenefits: "享优质专栏，会员专属资讯",
  highQualityColumn: "优质专栏",
  newCoinRecommendation: "新币推荐",
  exclusiveCustomerService: "专属客服",
  greatValueDiscounts: "超值优惠",
  memberBenefits: "会员权益",
  authorizationContract: "授权合约",
  pay: "支付",
  authorizationSuccessful: "授权成功",
  authorizationFailed: "授权失败",
  installMetaMask: "请安装 MetaMask 钱包插件",
  transactionSent: "交易已发送",
  transactionConfirmed: "交易已确认",
  transactionFailed: "交易失败",
  wantWithdrawal: "我要提现",
  withdrawalInstructions: "提现说明",
  withdrawTip: "请输入您要提现的数量",
  withdrawalAddressTip: "请输入提现地址",
  minimum: "最低",
  remaining: "剩余",
  WithdrawToCurrentAccount: "提现到当前账户",
  serviceCharge: "手续费",
  notice: "注意",
  inputWwithdrawalAmount: "请输入提现数量",
  withdrawalCannotLess: "提现数量不能小于",
  withdrawalSuccessful: "提现成功",
  accumulatedMembershipDevelopment: "已累计发展会员",
  accumulatedIncome: "累计收益",
  user: "用户",
  profit: "收益",
  noTeam: "暂无团队",
  name: "名称",
  price: "价格",
  cancel: "取消",
  noCollection: "暂无收藏",
  time: "时间",
  riskTip: "币圈有风险 应谨慎至上",
  noData: "暂无数据",
  copySuccess: "复制成功",
  WalletPlugInNotInstalled: "未安装钱包插件",
  openVipTip: "请先开通VIP",
  loginWallet: "请先登录钱包",
  goToLoginTip: "您还未登录,是否前往登录?",
  confirmExit: "确认退出登录?",
  loading: "加载中...",
  noMore: "没有更多了",
  views: "浏览量",
  backList: "返回列表",
  pushTime: "推送时间",
  insufficientBalance: "余额不足",
  failedObtainBalance: "获取余额失败",
  register: "注册",
  email: "邮箱",
  password: "密码",
  confirmPass: "确认密码",
  noAccount: "没有账号？",
  registerNewAccount: "注册新账号",
  hasAccount: "已有账号？",
  goToLogin: "前往登录",
  inputEmail: "请输入邮箱地址",
  inputValidEmail: "请输入有效的邮箱地址",
  inputPassword: "请输入密码",
  passwordLengthLimit: "密码长度至少为6个字符",
  inputPasswordAgain: "请再次输入密码",
  passwordAgainError: "两次输入的密码不一致",

  confirm: "确定",
  tip: "提示",
  changeChainTip: "您当前连接的是非以太坊主网，是否切换到以太坊主网？",
  switchingSuccessful: "切换成功",
  noChainChange: "目标网络尚未添加，请添加以太坊主网",
  recharge: "我要充值",
  rechargeTip: "请输入您要充值的数量",
  rechargeToCurrentAccount: "充值到当前账户",
  myOrder: "我的订单",
  paymentCode: "支付二维码",
  pushPrice: "推送价格",
  profit: "收益",
  DailyHighestPrice: "日最高价格",
  emailPush: "邮箱推送",
  bind: "绑定",
  bindEmail: "绑定邮箱",
  switchOn: "开启推送设置成功",
  switchOff: "关闭推送设置成功",
  bindSuccess: "绑定成功",
  bindFail: "绑定失败",
  openPushNotifications: "是否开启推送",
  withdrawalBtn: "提现",
  selectCurrency: "选择币种",
  searchCurrency: "请输入搜索币种",
  pleaseSelectCurrency: "请选择币种",
  paymentAmount: "支付金额",
  paymentAddress: "支付地址",
  alreadyBound: "已绑定邮箱",
  confirmWithdrawalAddress: "请确认是否提现到地址：",
};
