<template>
    <div class="pb-30 fc-ac">
        <div class="title">{{ $t('register') }}</div>
        <div v-if="checkIsMobile" class="fc-ac">
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="90px">
                <el-form-item :label="$t('email')" prop="email">
                    <el-input v-model="ruleForm.email"></el-input>
                </el-form-item>
                <el-form-item :label="$t('password')" prop="pass">
                    <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('confirmPass')" prop="checkPass">
                    <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="pl-20">
                        <el-button type="success" @click="submitForm('ruleForm')">{{ $t('register') }}</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <div class="ta-c">{{ $t('hasAccount') }}<el-button type="text" @click="toLogin()">{{ $t('goToLogin')
                    }}</el-button></div>
        </div>
        <el-card v-else shadow="hover" class="fc-ac" style="padding: 40px 60px 40px 40px;">
            <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px">
                <el-form-item :label="$t('email')" prop="email">
                    <el-input v-model="ruleForm.email"></el-input>
                </el-form-item>
                <el-form-item :label="$t('password')" prop="pass">
                    <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('confirmPass')" prop="checkPass">
                    <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="pl-20">
                        <el-button type="success" @click="submitForm('ruleForm')">{{ $t('register') }}</el-button>
                    </div>
                </el-form-item>
            </el-form>
            <div class="ta-c">{{ $t('hasAccount') }}<el-button type="text" @click="toLoginPc()">{{ $t('goToLogin')
                    }}</el-button></div>
        </el-card>
    </div>
</template>

<script>
import { getVipData } from '@/api/api'
import { mapState, mapGetters } from 'vuex'
import { Toast } from "vant";
import { registerAPI } from "@/api/login";
import store from "@/store";
export default {
    data() {
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('inputPasswordAgain')));
            } else if (value !== this.ruleForm.pass) {
                callback(new Error(this.$t('passwordAgainError')));
            } else {
                callback();
            }
        };
        return {
            ruleForm: {
                pass: '',
                checkPass: '',
                email: ''
            },
            rules: {
                email: [
                    { required: true, message: this.$t('inputEmail'), trigger: 'blur' },
                    { type: 'email', message: this.$t('inputValidEmail'), trigger: ['blur', 'change'] },
                ],
                pass: [
                    { required: true, message: this.$t('inputPassword'), trigger: 'blur' },
                    { min: 6, message: this.$t('passwordLengthLimit'), trigger: 'blur' },
                ],
                checkPass: [
                    { required: true, message: this.$t('inputPasswordAgain'), trigger: 'blur' },
                    {
                        validator: validatePass2,
                        trigger: 'blur'
                    },
                ],
            }
        }
    },
    watch: {

    },
    computed: {
        ...mapState({
            account1: state => state.login.account1
        }),
        ...mapGetters([
            'checkIsMobile',
        ]),
    },
    mounted() {

    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    registerAPI(this.ruleForm.email, this.ruleForm.pass).then((res) => {
                        if (res.data.code === 0) {
                            console.log("注册获取token：", res.data.data.userinfo.token);
                            store.commit("login/changeToken", res.data.data.userinfo.token);
                            this.$router.go(-1);
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            });
                        }
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        toLogin() {
            this.$router.replace('/mobile/login')
        },
        toLoginPc() {
            this.$router.replace('/pc/login')
        },
    }
}
</script>

<style lang="less" scoped>
.title {
    font-weight: bold;
    font-size: 42px;
    color: #000000;
    margin: 50px 0;
}

/deep/.el-button--text {
    color: #70ED24;
}

/deep/.el-button--text:focus,
.el-button--text:hover {
    color: #aff384;
    border-color: transparent;
    background-color: transparent;
}
</style>